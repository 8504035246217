import { BiRupee } from "react-icons/bi";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useState } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSwiper } from "swiper/react";
import { utilitySliceActions } from "../../Redux/Slice/UtilitySlice";
import arrow from "../../Assets/Arrow.svg";
import { FaCaretDown } from "react-icons/fa";
import { Button } from "react-bootstrap";


const SubscriptionCard = ({
  item,
  domainCount1,
  langCount1,
  selLang,
  selDomain,
  domainSub1,
  setPackInc,
  setPackTitle,
  setTitle,
  submitModal,
  setBoolaen,
  setPage,
  setCheckout,
  setCouponDetails,
  setShow,
  closeModal,
  pack,
  setPackTemp,
}) => {
  const { highlightIndex } = useSelector((state) => state.utils);
  const swiper = useSwiper();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      console.log("tokenValue", Cookies.get("token"));
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);
  useEffect(() => {
    ///static
    if (highlightIndex === 8) {
      swiper.slideTo(highlightIndex);
    }

    setTimeout(() => {
      dispatch(utilitySliceActions.setHighlightIndex(0));
    }, 200);
  }, [highlightIndex]);

  const subCouponpack = async (packs, domain, lang) => {
    let obj;
    if (domain?.length && lang?.length) {
      obj = {
        chosenDomainSubjects: domain,
        chosenLangSubjects: lang,
      };
    } else if (domain) {
      obj = {
        chosenDomainSubjects: domain,
      };
    } else {
      obj = {};
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/pg/getSubsPackCouponsTaxes/${packs.subscriptionId}`,
      obj,
      {
        headers: {
          "Acces-Control-Allow-Origin": "*",
          Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          Authorization: `${Cookies.get("token")}`,
        },
      }
    );
    setCheckout(data.Data);
    setCouponDetails(data.Data.avlCouponsList);
    setShow(true);
    localStorage.setItem('subscriptionDetails', JSON.stringify(data.Data));
  };

  const showModal = (id, label, showPopup) => {
    closeModal();
    setTitle(label);
    let temPack = pack.filter((x) => x.subscriptionId === id);
    setPackTemp(temPack);
    submitModal("avlDomainSubjects", "defaultDomainSubjCount", temPack);
    setBoolaen((prev) => ({ ...prev, disDomain: false, alertTxt: false }));
    setBoolaen((prev) => ({ ...prev, [showPopup]: true }));
  };

  const handlePayment = async (subPack) => {
    if (!isLoggedIn) {
      window.location.href = "/signin"
    };

    setPage(0);
    let selectDomain1 = subPack.avlDomainSubjects?.filter(
      (x) => x.specialInstruction === true
    );
    let selectLang1 = subPack.avlLangSubjects?.filter(
      (x) => x.specialInstruction === true
    );

    if (subPack.avlDomainSubjectsLbl && subPack.avlLangSubjectsLbl) {
      if (
        selectDomain1?.length >= subPack?.defaultDomainSubjCount &&
        selectLang1?.length >= subPack?.defaultLangSubjCount
      ) {
        subCouponpack(subPack, selectDomain1, selectLang1);
      } else {
        //inline toast message
        Swal.fire({
          icon: "warning",
          html: `<h5>${subPack.subscriptionName}</h5>Please select ${subPack.defaultDomainSubjCount < 4
            ? "atleast " + subPack.defaultDomainSubjCount
            : subPack.defaultDomainSubjCount
            }  domain subject(s) and ${subPack.defaultLangSubjCount > 0
              ? subPack.defaultLangSubjCount
              : "non-mandatory"
            } language to proceed.`,
          toast: true,
          customClass: {
            popup: "colored-toast",
          },
          text: ``,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 6000,
          timerProgressBar: true,
        });
      }
    } else {
      if (subPack.avlDomainSubjectsLbl) {
        if (selectDomain1.length >= subPack.defaultDomainSubjCount) {
          subCouponpack(subPack, selectDomain1);
        } else {
          alert(
            `please select ${subPack.defaultDomainSubjCount} domain subject`
          );
        }
      } else {
        subCouponpack(subPack);
      }
    }
  };

  const handleViewMore = (
    packInclusions,
    packInclusionLbl,
    subscriptionName
  ) => {
    setPackInc(packInclusions);
    setTitle(packInclusionLbl);
    setPackTitle(subscriptionName);

  };

  function getColorBySubscriptionId(subscriptionId) {
    const maxSubscriptionId = 10;
    const colorMapping = {
      1: "rgba(65, 105, 225, 1)", // Royal Blue
      2: "rgba(255, 140, 0, 1)", // Dark Orange
      3: "rgba(60, 179, 113, 1)", // Medium Sea Green
      4: "rgba(255, 20, 147, 1)", // Deep Pink
      5: "rgba(0, 191, 255, 1)", // Deep Sky Blue
      6: "rgba(255, 215, 0, 1)", // Gold
      7: "rgba(255, 99, 71, 1)", // Tomato
      8: "rgba(128, 0, 128, 1)", // Purple
      9: "rgb(183 100 115)", // Pink
      10: "rgba(46, 139, 87, 1)", // Sea Green
    };

    if (subscriptionId <= maxSubscriptionId) {
      return colorMapping[subscriptionId];
    } else {
      const adjustedId = subscriptionId % maxSubscriptionId;
      return colorMapping[adjustedId === 0 ? maxSubscriptionId : adjustedId];
    }
  }
  function getLighterShade(color) {
    // Parse the color string to extract its components
    const rgbaValues = color.match(/\d+/g).map(Number);
    const alpha = rgbaValues[3]; // Extract the alpha value

    // Reduce the alpha value by subtracting a fraction
    const lighterAlpha = alpha - 0.2; // Decrease alpha for a lighter shade

    // Ensure the alpha remains within the valid range (0 to 1)
    const newAlpha = Math.max(0, Math.min(1, lighterAlpha));

    // Generate the new RGBA string with the updated alpha value
    return `rgba(${rgbaValues[0]}, ${rgbaValues[1]}, ${rgbaValues[2]}, ${newAlpha})`;
  }

  return (
    <section className="subscription-card" style={{ padding: "20px" }}>
      <div
        style={{
          backgroundColor: getLighterShade(
            getColorBySubscriptionId(item.subscriptionId)
          ),
          padding: "30px",
          borderRadius: "5px 0px 0px 0px",
          marginBottom: "50px",
          // width: "100%",
          height: "30%",
        }}
      >
        {/* {item.tagLine && <span className="ribbon-pop">{item.tagLine}</span>} */}
        <p className="mb-2 subscription-card-title">{item.subscriptionName}</p>
        {/* <img
                src={arrow}
                alt="Down Arrow"
                className="down-arrow"
              /> */}
      </div>

      <article
        className="card_body  flex-grow-1 d-flex flex-column justify-content-between"
        style={{ width: "100%" }}
      >
        <section style={{ marginBottom: "20px" }}>
          <span style={{ marginTop: "50px" }}>
            <span style={{ fontSize: "1.5em" }}> Rs. </span>
            <span
              style={{
                fontSize: "2em",
                color: getColorBySubscriptionId(item.subscriptionId),
                fontWeight: "bold",
              }}
            >
              {item.discountedPrice}
            </span>{" "}
            / {parse(String(item.packValidity))}
          </span>

          <div className="pack_inclusion" style={{ marginTop: "20px" }}>
            <ul
              style={{ listStyleType: "none", padding: "0", marginTop: "10px" }}
            >
              {item.packInclusions.map((pack, index) => {
                if (index < 2) {
                  return (
                    <li key={index} style={{ marginBottom: "8px" }}>
                      {parse(String(pack))}
                    </li>
                  );
                } else if (index === 2) {
                  return (
                    <span
                      className="features"
                      onClick={() =>
                        handleViewMore(
                          item.packInclusions,
                          item.packInclusionLbl,
                          item.subscriptionName
                        )
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#subModal"
                      style={{ cursor: "pointer", color: "#007BFF" }}
                    >
                      View More
                    </span>
                  );
                }
              })}
              <div className="d-flex flex-column mt-4">
                {item.avlDomainSubjectsLbl && (
                  <li className="d-flex">
                    <a
                      className="fw-bold btn send_msg_btn py-1"
                      onClick={() =>
                        showModal(
                          item.subscriptionId,
                          item.avlDomainSubjectsLbl,
                          "showDomain"
                        )
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#subModal"
                    // style={{ marginBottom: "8px" }}
                    >
                      Select Domain Subjects
                    </a>
                  </li>
                )}
                {item.avlLangSubjects?.length && (
                  <li className="d-flex">
                    <a
                      className="fw-bold btn send_msg_btn py-1 mt-4 d-flex"
                      onClick={() =>
                        showModal(
                          item.subscriptionId,
                          item.avlLangSubjectsLbl,
                          "showLang"
                        )
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#subModal"
                      style={{ marginBottom: "8px", paddingLeft: "62px", paddingRight: "62px" }}
                    >
                      Select Languages
                    </a>
                  </li>
                )}
              </div>
            </ul>
          </div>
        </section>

        {
          item.avlDomainSubjectsLbl && (
            <>
              <p className="mb-0">
                <strong style={{ color: 'black' }}>Domain Subjects Opted:</strong>
              </p>
              {domainCount1 || (selDomain && item.subscribedFlag) ? (
                <p className="m-2">{domainSub1.join(", ")}</p>
              ) : (
                <span></span>
              )}
            </>
          )
        }

        {/* {item.avlLangSubjects?.length && (
			<>
			  <p className="m-0">
				<strong>Language Opted:</strong>
			  </p>
			  {langCount1 || (selLang && item.subscribedFlag) ? (
				<p className="m-2">
				  {item.avlLangSubjects.map((domain) => (
					<span>
					  {domain.specialInstruction || domain.selection
						? domain.topicName
						: " "}
					</span>
				  ))}
				</p>
			  ) : (
				<span>-</span>
			  )}
			</>
		  )} */}
      </article >

      <div
        className="card_footer justify-content-between"
        style={{ marginTop: "20px" }}
      >
        <button
          className="btn main-btn"
          style={{
            width: "200px",
            backgroundColor: getColorBySubscriptionId(item.subscriptionId),
          }} // Adjust the width as needed
          data-bs-toggle="modal"
          data-bs-target={
            Cookies.get("token")
              ? item.avlDomainSubjectsLbl && item.avlLangSubjectsLbl
                ? domainCount1 >= item.defaultDomainSubjCount &&
                  langCount1 >= item.defaultLangSubjCount
                  ? "#PaymentModal"
                  : ""
                : item.avlDomainSubjectsLbl
                  ? domainCount1 >= item.defaultDomainSubjCount
                    ? "#PaymentModal"
                    : ""
                  : "#PaymentModal"
              : "#login"
          }
          onClick={() => {
            handlePayment(item);
          }}
        // disabled={isLoggedIn ? false : true}
        >
          {item.discountedPrice == 0 ? "ENJOY FREE CLASSES" : "Choose Plan"}
        </button>
      </div>
    </section >
  );
};

export default SubscriptionCard;
