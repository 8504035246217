import React, { useEffect, useState } from 'react'
import { Accordion, Card, Col, Image, Row, Button, Navbar, Container, Form, Spinner } from 'react-bootstrap';
import '../CTET.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LuArrowUpRightSquare } from "react-icons/lu";
import './page.css'
import AdsFormModel from './AdsFormModel';
import expert1 from '../../../../Assets/images/Expert1.jpg'
import expert2 from '../../../../Assets/images/Expert2.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import { FormSchema } from './ValidationSchema.js'
import { adsPageTodo } from '../../../../Redux/Thunks/Post/PostAdsPageThunk.js';
import { useDispatch } from 'react-redux'
import Swal from "sweetalert2";
import { FaArrowRight } from 'react-icons/fa';


export default function AddPageCuet() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [bool, setBool] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const initialValues = {
        name: "",
        email: "",
        phone: "",
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: FormSchema,

        onSubmit: (values, action) => {
            // console.log(values)
            setSpinner(true)
            dispatch(adsPageTodo({ 'values': values })).then((res) => add_res(res.payload, action))

        }
    })


    const getstart = () => {
        // navigate('/cuetexamcourse')
        window.location.href = "/cuetexamcourse";
    }


    const add_res = (res, action) => {
        if (res?.status == 201) {
            action.resetForm();
            setSpinner(false)
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thank you for Enrolling. We will get back to you shortly.',
                position: 'center',
                showConfirmButton: false,
                timer: 1500
            });
            setBool(true)
            // gtag_report_conversion()
            // // Load Google Analytics script only on success
            // const script = document.createElement('script');
            // script.src = "https://www.googletagmanager.com/gtag/js?id=G-GV4QNEBFZJ";
            // script.async = true;
            // document.head.appendChild(script);

            // // Initialize Google Analytics tracking
            // script.onload = () => {
            //     // Ensure window.dataLayer is defined
            //     window.dataLayer = window.dataLayer || [];
            //     function gtag() { window.dataLayer.push(arguments); }

            //     gtag('js', new Date());
            //     gtag('config', 'G-GV4QNEBFZJ');
            // };

            // console.log(script, 'run')

        } else if (res?.status == 400) {
            Swal.fire({
                icon: 'error',
                title: '400 BAD REQUEST',
                text: 'The request could not be understood by the server.',
                position: 'center',
                showConfirmButton: true
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',
                position: 'center',
                showConfirmButton: true
            });
        }
    };


    const Testimonial = [

        {
            "type": 1,
            "imageURL": "tonmoy.png",
            "mediType": 1,
            "name": "Tonmoy Kashyap",
            "link": "page link",
            "content": ` During my CUET exam preparations, I took live classes on
          General Test papers provided by www.besst.in.I also practised
          their mock tests.`
        },
        {
            "type": 1,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Vijay Jain",
            "link": "page link",
            "content": `Huge thanks to Besst for helping me crack the CUET 2024 and get into Delhi University! The DU expert faculty were supportive and made learning enjoyable. Their tips and study materials were spot on. Totally worth it!`
        },
        {
            "type": 1,
            "imageURL": "rukh.png",
            "name": "Rukh Acharya",
            "mediType": 1,
            "link": "page link",
            "content": `  I'm glad that I got to learn about CUET and its fundamentals
          from BESST. It helped me get into a college in Noida. I'm
          thankful to you all`
        },
        // {
        //     "type": 1,
        //     "imageURL": "gunjan.svg",
        //     "name": "Parashmoni Boruah",
        //     "mediType": 1,
        //     "link": "page link",
        //     "content": ` I have taken online classes with Kaushik Sir; he was the perfect
        //   trainer for me I could have imagined. He has been very patient
        //   with me and always very supportive. He starts from basics and
        //   gradually gets into advanced topics. The study material that sir
        //   provided was very useful; students with any level of knowledge
        //   can start, learn, and excel. Its practice papers and mock test
        //   papers helped me a lot, and the materials of Cuet and UG are
        //   very informative. Thank you sir for your support 😎`
        // },
        {
            "type": 2,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Chumi",
            "link": "page link",
            "content": "This web portal is very much useful for any kind of competitive exam preparation.. Specially for cuet.. And also provides live classes...mock tests are also available.. There are lots of questions for every subject for regular practice.. Thank you BESST for providing best materials."
        },
        {
            "type": 2,
            "imageURL": "gunjan.svg",
            "mediType": 1,
            "name": "Krishna Maheshwari",
            "link": "page link",
            "content": "The material provided is quite helpful.A great initiative.Wishing more success and growth to BESST!"
        }
    ];

    const responsive = {
        5000: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        1000: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        600: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        0: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    useEffect(() => {
        // Dynamically inject the gtag script
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-GV4QNEBFZJ";
        document.head.appendChild(script);

        // Initialize gtag
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-GV4QNEBFZJ");

        function gtag_report_conversion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-16493932126/rDuxCP_CuKAZEN7c9bg9',
                'event_callback': callback
            });

            return false;
        }

        if (bool) {
            gtag_report_conversion()
            setBool(false)
        }

        return () => {
            document.head.removeChild(script);
        };

    }, [bool]);

    return (
        <>

            <Navbar expand="lg">
                <Container className="d-flex">
                    <Link to="/">
                        <Navbar.Brand>
                            <img
                                alt=""
                                src={`${process.env.PUBLIC_URL}/assets/logo/7ac1037e455cb7559800318b98569c40.png`}
                                width="100"
                                height="100"
                                flex-shrink="0"
                                className="d-inline-block align-top mx-3"
                            />
                        </Navbar.Brand>
                    </Link>
                </Container>
            </Navbar>

            {/* Main Content */}
            <div className="row">
                {/* <div style={{ backgroundColor: '5A1E7D' }}>


                    <img src={`${process.env.PUBLIC_URL}assets/join_img/ads_image.jpg`} className="img-fluid" style={{ width: '100%', }} onClick={() => setShow(true)}
                    />

                </div> */}
                {/* Join CTA */}

                <div className='ads_img'>
                    {/* <div className=" "> */}
                    <div className='row mb-2'>
                        <div className='col-md-7 text-center banner-text' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <h1 style={{ fontSize: '45px', textAlign: 'center', color: 'white' }} className='mt-5 ms-3'>Get Ready for <span style={{ color: '#fcc705' }}>
                                CUET PG</span> 2025
                                {/* with <br /> <span style={{ color: '#fcc705' }}>
                                    BESST</span> Educational Hub */}
                            </h1>
                            <p className='text-center p-5' style={{ color: 'white', fontSize: '25px' }}>Enroll in our specialized program for comprehensive guidance, expert faculty, and personalized learning plans</p>
                        </div>
                        <div className=' col-md-5 ps-5 pe-5' style={{ paddingTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} style={{ marginBottom: '-100px' }}>
                                <div className='w-100' style={{ backgroundColor: '#fcc705', padding: '30px 30px 30px 30px ', borderRadius: '10px' }}>
                                    <h1 className='hone_size text-dark mb-5'>Fill out the form to know more</h1>
                                    <div className="w-100 mt-1">
                                        <Form.Label className=' text-dark text-start d-flex mt-2 mb-2 fs-5'>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            // size="sm"
                                            placeholder={'Enter Name'}
                                            className='form_input fs-5'
                                            name={'name'}
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        {errors.name && touched.name ? (<span className='text-danger p-1 d-flex justify-content-start' style={{ fontSize: '15px' }} >{errors.name}</span>) : null}
                                    </div>


                                    <div className="w-100">
                                        <Form.Label className='text-dark text-start d-flex mt-5 mb-2 fs-5'>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            // size="sm"
                                            placeholder={'Enter Email'}
                                            className='form_input fs-5'
                                            name={'email'}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.email && touched.email ? (<span className='text-danger p-1 d-flex justify-content-start' style={{ fontSize: '15px' }} >{errors.email}</span>) : null}
                                    </div>

                                    <div className="w-100">
                                        <Form.Label className=' text-dark text-start d-flex mt-5 mb-2 fs-5'>Mobile No.</Form.Label>
                                        <Form.Control
                                            type="number"
                                            // size="sm"
                                            placeholder={'Enter Phone'}
                                            className='form_input fs-5'
                                            name={'phone'}
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.phone && touched.phone ? (<span className='text-danger p-1 d-flex justify-content-start' style={{ fontSize: '15px' }} >{errors.phone}</span>) : null}
                                    </div>

                                    <div className='col-md-12 mt-5 mb-2 text-start' style={{}}>

                                        <Button style={{ fontSize: '20px' }} type='submit' size='sm' variant="primary" className="reg_btn mb-2 mt-2 w-100 button_loading d-flex align-items-center justify-content-center" disabled={spinner} >
                                            Submit
                                            {spinner ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className='ms-1'
                                                />
                                                : ''
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </Form >
                        </div>
                    </div>
                    {/* </div> */}
                </div>

                {/* Why BESST Educational Hub? */}

                <div className="row">
                    <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                        <div className='col-md-7 col-sm-12 form_query_content' >
                            <h2 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='htwo_size mb-5'> Why BESST?</h2>
                            <div className="cuetctn">
                                <ul style={{ textAlign: "justify", fontSize: '20px', listStyleType: 'disclosure-closed' }} className='mt-2 mb-2'>
                                    <li className='mb-3 mt-5 ms-5'>
                                        Expert faculty with years of experience
                                    </li>
                                    <li className='mb-3 mt-3 ms-5'>
                                        Complete coverage of the CUET PG syllabus
                                    </li>
                                    <li className='mb-3 mt-3 ms-5'>
                                        Mock tests, practice papers, and doubt-clearing sessions
                                    </li>
                                    <li className='mb-3 mt-3 ms-5'>
                                        Offline and online classes for flexible learning
                                    </li>
                                    <li className='mb-3 mt-3 ms-5'>
                                        Special attention to time management and test-taking strategies
                                    </li>
                                    <li className='mb-3 mt-3 ms-5'>
                                        Centers in Delhi and Guwahati
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-5 col-sm-12 form_query'>

                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators justify-content-center dot_style" >
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" style={{ height: '10px', width: '10px', borderRadius: '50%' }} ></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1" style={{ height: '10px', width: '10px', borderRadius: '50%' }} ></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2" style={{ height: '10px', width: '10px', borderRadius: '50%' }} ></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <Image
                                            src={`${process.env.PUBLIC_URL}/assets/cuetimg/bscdegreecourse-1647858929.jpg`}
                                            className="courseimg img-fluid d-block w-100"
                                            rounded
                                            alt="CTET"
                                            style={{ height: '40vh', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div className="carousel-item">
                                        <Image
                                            src={`${process.env.PUBLIC_URL}/assets/cuetimg/987883-results.jpg`}
                                            className="courseimg img-fluid d-block w-100"
                                            rounded
                                            alt="CTET"
                                            style={{ height: '40vh', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div className="carousel-item">
                                        <Image
                                            src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
                                            className="courseimg img-fluid d-block w-100"
                                            rounded
                                            alt="CTET"
                                            style={{ height: '40vh', objectFit: 'cover' }}
                                        />
                                    </div>
                                </div>

                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>




                    </div>
                </div>

                {/* 
                <div className="expert-section p-5">
                    <div className="row profile-row">
                        
                            <h2 className="htwo_size p-3">Our Experts</h2>
                        

                        <div className="col-md-6 col-sm-6 profile-col">
                            <div className="profile-card">
                                <img src={expert1} alt={'expert1'} className="profile-image" />
                                <h3 className="profile-name">Dr.Pranjal Bezbaruah</h3>
                                <p className="profile-designation">
                                    (Educationist, Rtd Professor, Dibrugarh University)
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-6 profile-col">
                            <div className="profile-card">
                                <img src={expert2} alt={'expert2'} className="profile-image" />
                                <h3 className="profile-name">Dr. Rajni Lamba</h3>
                                <p className="profile-designation">(Renowned Anthropologist)</p>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className="team-section">
                    <div className="team-header">
                        <h2 className="team-heading htwo_size">Our Experts</h2>
                        {/* <p className="team-subtitle">Our dedicated team of professionals</p> */}
                    </div>

                    <div className="row team-row">
                        <div className="col-md-4 col-sm-12">
                            <div className="team-card">
                                <img src={expert1} alt="Dr. Pranjal Bezbaruah" className="team-image" />
                                <h3 className="team-name">Dr. Pranjal Bezbaruah</h3>
                                <p className="team-role">Educationist, Rtd Professor</p>
                                <p className="team-description">
                                    Dibrugarh University, highly experienced in educational development.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-12">
                            <div className="team-card">
                                <img src={expert2} alt="Dr. Rajni Lamba" className="team-image" />
                                <h3 className="team-name">Dr. Rajni Lamba</h3>
                                <p className="team-role">Renowned Anthropologist</p>
                                <p className="team-description">
                                    Expert in the field of anthropology with significant global recognition.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>




                {/* Success Stories  */}

                <div className="">
                    <div className="testimonial-area">
                        <div className="testimoniyal_container" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="">
                                <h2 className="htwo_size title-success">Success Stories </h2>
                            </div>
                            <OwlCarousel
                                className="testimonial-content owl-carousel"
                                margin={50}
                                smartSpeed={800}
                                autoplay={false}
                                autoplayTimeout={4000}
                                dots={true}
                                nav={false}
                                responsive={responsive}
                                height={5}
                            >
                                {Testimonial?.map((item, index) => (
                                    <div className="single-testimonial match_height" key={index}>
                                        <div className="container-fluid mt-5 pt-5" style={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {item.mediType == 2 ?
                                                (
                                                    <div >
                                                        <video
                                                            className="intro-video mb-3"
                                                            src={`${process.env.PUBLIC_URL}/assets/testimonials/${item.videoURL}`}
                                                            type="video/mp4"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                            controls
                                                        ></video>

                                                    </div>
                                                ) : (
                                                    <p className="text_para text-paragraph fs-5 p-4">{item.content}</p>
                                                )}
                                        </div>
                                        {/*  */}
                                        <div className="client-info">
                                            {/* <div className="client-video mt-5 pt-5 mb-3 me-3">
                                                {item.imageURL ?
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/assets/testimonials/${item.imageURL}`}
                                                        alt="frame"
                                                        className="Caor_img"
                                                        // fluid
                                                        style={{ backgroundColor: item.imageURL === "gunjan.svg" ? "#fff" : "", width: '100px', height: '100px', borderRadius: '50%' }}
                                                    />
                                                    :
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`}
                                                        alt="frame"
                                                        className="Caor_img"
                                                        // fluid
                                                        style={{ backgroundColor: item.imageURL === "gunjan.svg" ? "#fff" : "", width: '100px', height: '100px', borderRadius: '50%' }}
                                                    />
                                                }

                                            </div> */}
                                            <div className="client-details ms-3">
                                                <h6>{item.name}</h6>
                                                {/* <Button type="button" className="caro_btn w-100" size="sm" href={item.type == 2 ? "/testimonials" : "/success-stories"}>
                                                    View Full Story

                                                    <LuArrowUpRightSquare className="ms-2" />
                                                </Button> */}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>

                </div>

                {/* CUET PG 2025 Program Highlights */}

                <div className="ctet_syllabus container mt-5">
                    <div className="align_center ml-2">
                        <h2 style={{
                            fontSize: '1.5em', marginTop: '15px', color: 'black'
                        }} className='text-center htwo_size'>CUET PG 2025 Program Highlights</h2>
                        <div md={11} sm={11} xs={11}>

                            <Row className="intro-cards-container m-2">
                                <Col lg={4} md={6} sm={12} xs={12} className='p-3' >
                                    <div className="intro_ctet_card p-2 " style={{ borderRadius: "98px", background: '#4d4d4d' }}>
                                        <Row className="intro-center">

                                            <div className="col-md-3 text-center">
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                    alt="frame"
                                                    fluid
                                                    style={{
                                                        background: "white",
                                                        border: "10px solid white",
                                                        borderRadius: "50px"
                                                    }}
                                                />
                                            </div>

                                            <div className="text-md-start text-center col-md-9" style={{ padding: "10px 35px 8px 26px" }}>
                                                <p className="user_name">100+ hours of live classes</p>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className='p-3' >
                                    <div className="intro_ctet_card p-2 " style={{ borderRadius: "98px", background: '#4d4d4d' }}>
                                        <Row className="intro-center">

                                            <div className="col-md-3 text-center">
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                    alt="frame"
                                                    fluid
                                                    style={{
                                                        background: "white",
                                                        border: "10px solid white",
                                                        borderRadius: "50px"
                                                    }}
                                                />
                                            </div>
                                            <div className="text-md-start text-center col-md-9" style={{ padding: "10px 35px 8px 26px" }}>
                                                <p className="user_name">Detailed study materials curated by experts </p>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className='p-3' >
                                    <div className="intro_ctet_card p-2 " style={{ borderRadius: "98px", background: '#4d4d4d' }}>
                                        <Row className="intro-center">
                                            <div className="col-md-3 text-center">
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                    alt="frame"
                                                    fluid
                                                    style={{
                                                        background: "white",
                                                        border: "10px solid white",
                                                        borderRadius: "50px"
                                                    }}
                                                />
                                            </div>
                                            <div className="text-md-start text-center col-md-9" style={{ padding: "10px 35px 8px 26px" }}>
                                                <p className="user_name">Weekly mock tests and performance analysis</p>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={2} md={6} sm={12} xs={12} className='p-3' >

                                </Col>
                                <Col lg={4} md={6} sm={12} xs={12} className='p-3' >
                                    <div className="intro_ctet_card p-2 " style={{ borderRadius: "98px", background: '#4d4d4d' }}>
                                        <Row className="intro-center">
                                            <div className="col-md-3 text-center">
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                    alt="frame"
                                                    fluid
                                                    style={{
                                                        background: "white",
                                                        border: "10px solid white",
                                                        borderRadius: "50px"
                                                    }}
                                                />
                                            </div>
                                            <div className="text-md-start text-center col-md-9" style={{ padding: "10px 35px 8px 26px" }}>
                                                <p className="user_name">Access to recorded sessions for revision </p>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>

                                <Col lg={4} md={6} sm={12} xs={12} className='p-3' >
                                    <div className="intro_ctet_card p-2 " style={{ borderRadius: "98px", background: '#4d4d4d' }}>
                                        <Row className="intro-center">
                                            <div className="col-md-3 text-center">
                                                <Image
                                                    src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                                                    alt="frame"
                                                    fluid
                                                    style={{
                                                        background: "white",
                                                        border: "10px solid white",
                                                        borderRadius: "50px"
                                                    }}
                                                />
                                            </div>
                                            <div className="text-md-start text-center col-md-9" style={{ padding: "10px 35px 8px 26px" }}>
                                                <p className="user_name">Personalized feedback and one-on-one mentoring </p>
                                            </div>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={2} md={6} sm={12} xs={12} className='p-3' >

                                </Col>
                            </Row>
                            {/* <div className='col-md-12 text-center mb-5 mt-5 d-flex justify-content-center'>
                                <button
                                    className="reg_btn "
                                    type="button"
                                    style={{ borderRadius: '15px' }}
                                >
                                    Download Brochure
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Success Stories from Our CUET Alumni */}
                {/* <div className="mt-5">
                    <div className="testimonial-area">
                        <div className="testimoniyal_container" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="">
                                <h2 className="htwo_size title-success">Success Stories from Our CUET Alumni </h2>
                            </div>
                            <OwlCarousel
                                className="testimonial-content owl-carousel"
                                margin={50}
                                smartSpeed={800}
                                autoplay={false}
                                autoplayTimeout={4000}
                                dots={true}
                                nav={false}
                                responsive={responsive}
                                height={10}
                            >
                                {Testimonial?.map((item, index) => (
                                    <div className="single-testimonial match_height" key={index}>
                                        <div className="container-fluid mt-5 pt-5" style={{ height: '30vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {item.mediType == 2 ?
                                                (
                                                    <div >
                                                        <video
                                                            className="intro-video mb-3"
                                                            src={`${process.env.PUBLIC_URL}/assets/testimonials/${item.videoURL}`}
                                                            type="video/mp4"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen
                                                            controls
                                                        ></video>

                                                    </div>
                                                ) : (
                                                    <p className="text_para text-paragraph">{item.content}</p>
                                                )}
                                        </div>
                                        {/*  
                                        <div className="client-info">
                                            <div className="client-video mt-5 pt-5 mb-3 me-3">
                                                {item.imageURL ?
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/assets/testimonials/${item.imageURL}`}
                                                        alt="frame"
                                                        className="Caor_img"
                                                        // fluid
                                                        style={{ backgroundColor: item.imageURL === "gunjan.svg" ? "#fff" : "", width: '100px', height: '100px', borderRadius: '50%' }}
                                                    />
                                                    :
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`}
                                                        alt="frame"
                                                        className="Caor_img"
                                                        // fluid
                                                        style={{ backgroundColor: item.imageURL === "gunjan.svg" ? "#fff" : "", width: '100px', height: '100px', borderRadius: '50%' }}
                                                    />
                                                }

                                            </div>
                                            <div className="client-details ms-3">
                                                <h6>{item.name}</h6>
                                                <Button type="button" className="caro_btn w-100" size="sm" href={item.type == 2 ? "/testimonials" : "/success-stories"}>
                                                    View Full Story
                                                    {/* <Image
                        src={`${process.env.PUBLIC_URL}/assets/caroselimg/Frame 185460.png`}
                        alt="icon"
                        className="ms-2"
                        style={{ width: "20px", height: "20px" }}
                      /> 
                                                    <LuArrowUpRightSquare className="ms-2" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>

                </div> */}

                {/* How to enroll */}
                <div className="container mt-4 mb-4">

                    <Row className="gs-5 ge-5">
                        <Col md={12} className='text-center mt-4 mb-4'>
                            <h2 style={{ color: 'Black', fontSize: '1.5em' }} className='htwo_size'>How to Enroll?</h2>
                        </Col>

                        <Col md={3} >
                            <Card className="custom-card text-center h-100 ">
                                <div className="red-banner">
                                    <h5>Step 1</h5>
                                </div>
                                <Card.Body className="custom-card-body">
                                    <Card.Text >
                                        <div style={{ fontSize: '16px' }}>
                                            Fill out the form and get in touch with our team
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Search Field Card */}
                        <Col md={3} >
                            <Card className="custom-card text-center  h-100">
                                <div className="red-banner">
                                    <h5>Step 2</h5>
                                </div>
                                <Card.Body className="custom-card-body">
                                    <Card.Text>
                                        <div style={{ fontSize: '16px' }}>
                                            Get a free counseling session on how we can help you with CUET PG 2025
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Pagination Card */}
                        <Col md={3}>
                            <Card className="custom-card text-center  h-100">
                                <div className="red-banner">
                                    <h5>Step 3</h5>
                                </div>
                                <Card.Body className="custom-card-body">
                                    <Card.Text>
                                        <div style={{ fontSize: '16px' }}>
                                            Choose your preferred batch (offline/online)
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                        {/* Image Carousel Card */}
                        <Col md={3} className='mt-3 justify-content-center'>
                            <Card className="custom-card text-center  h-100">
                                <div className="red-banner">
                                    <h5>Step 4</h5>
                                </div>
                                <Card.Body className="custom-card-body">
                                    <Card.Text>
                                        <div style={{ fontSize: '16px' }}>
                                            Secure your spot by paying the enrollment fee
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>

                </div>

                <div className='col-md-12 text-center mb-5 mt-5'>
                    <button
                        className=" fs-5 p-4"
                        type="button"
                        onClick={() => getstart()}
                        style={{
                            borderRadius: "15px", background: '#fcc705', color
                                : '#000'
                        }}
                    >
                        Get Started Now <FaArrowRight className='ms-2' />
                    </button>
                </div>

                {/* Frequently Asked Questions */}

                <div className='ctet_question mt-5' style={{ marginBottom: '-50px' }}>
                    <div className=''>
                        <div className='text-center m-5 p-2'>
                            <h2 style={{ color: 'white' }} className='htwo_size'>Frequently Asked Questions</h2>
                        </div>
                        <div className="faq_head"></div>
                        <Accordion className='ms-5 me-5 mb-5'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="accordion_header">
                                    1. When does the CUET PG 2025 batch start?
                                </Accordion.Header>
                                <Accordion.Body style={{ background: '#e3d4eb' }}>
                                    A. Enrollment started, the classes will start from November, 2024.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="accordion_header">
                                    2. What is the duration of the course?
                                </Accordion.Header>
                                <Accordion.Body style={{ background: '#e3d4eb' }}>
                                    A. Course duration is 6 months.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header className="accordion_header">
                                    3. Can I attend demo classes before enrolling?
                                </Accordion.Header>
                                <Accordion.Body style={{ background: '#e3d4eb' }}>
                                    A. YES.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className="accordion_header">
                                    4. Are there any scholarship options?
                                </Accordion.Header>
                                <Accordion.Body style={{ background: '#e3d4eb' }}>
                                    A. Discount options are available.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header className="accordion_header">
                                    5. How do I access online classes?
                                </Accordion.Header>
                                <Accordion.Body style={{ background: '#e3d4eb' }}>
                                    A. After registration and enrollment, the students will get notification, time, date and other detials about the live classes in our portal.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>

            </div>

            <AdsFormModel show={show} setShow={setShow} />
        </>
    )
}
