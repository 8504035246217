/* eslint-disable */
import React, { useEffect, useState } from "react";
import Ellipse from "../../Assets/images/Ellipse.png";
import Ellipse1 from "../../Assets/images/Ellipse1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import slider_img from "../../Assets/images/first_slide.png";
import slider_img1 from "../../Assets/images/first1_slide.png";
import FacebookLogin from "react-facebook-login";
import google_icon from "../../Assets/images/Google_Logo.svg";
import Logo from "../../Assets/images/logo.png";
// Google:Login
import { gapi } from "gapi-script";
import img_frame from "../../Assets/images/Frame1.png";
import { Form } from "react-bootstrap";
import "./BesstPage.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { useInView } from "react-intersection-observer";
import "../Landing/landing.css";
import useRemoveModal from "../../Components/useRemoveModal";
import { EncryptText } from "../../Components/Encrypt/CryptoEncryption";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { AiFillEye, AiFillEyeInvisible, AiOutlineMail } from "react-icons/ai";
import { ImWhatsapp, ImYoutube2 } from "react-icons/im";
import { GoLocation } from "react-icons/go";
import { BiRupee } from "react-icons/bi";
import Header from "../../Components/Global/Navigation/Header";
import FAQ from "../../Components/NewLanding/FAQ";
import RegistrationModal from "../../Components/NewLanding/RegistrationModal";
import { register, studDashboard } from "../../RouteConstants";
import { Helmet } from "react-helmet-async";

function VideoData(data) {
	return (
		<>
			<div className='col-md-12'>
				<iframe
					src={data.url}
					allowFullScreen='allowfullscreen'
					title='YouTube video player'
					frameBorder='2'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					width='100%'
				></iframe>
			</div>
			<div className='col-md-12' style={{ paddingTop: 20 }}>
				{data.title}
				{/* <p className="small">Lorem ipsum dolor sit amet. {"[ABOUT]"}</p> */}
			</div>
			<div className='col-md-3'></div>
		</>
	);
}

function BestOnlinePlatform() {
	const baseUrl = process.env.REACT_APP_BASE_URL
	const clientId =
		"687458829496-83t97ka8jja2dvulr4ik8un4t262a2ac.apps.googleusercontent.com";
	// console.count("Initial-BesstNewExamPage Render");
	const history = useNavigate();
	const [email, setEmail] = useState("");
	const [courseUpdate, setCourseUpdate] = useState([]);
	const [selectedCourse, setSelectedCourse] = useState("");
	const [password, setPassword] = useState("");
	const [couresData, setCourseData] = useState([]);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [mobile, setMobile] = useState("");
	const [loading, setLoading] = useState(false);
	const [otpBox, setOtpBox] = useState(false);
	const [emailValidation, setEmailValidation] = useState(false);
	const [isPassed, setIsPassed] = useState(false);
	const [passChecker, setPassChecker] = useState(false);
	const [isPass, setIsPass] = useState(false);
	// const [courseDetails, setCourseDetails] = useState([]);
	const [registerSuccess, setRegisterSuccess] = useState("");

	const [whatsappNumber, setWhatsappNumber] = useState("");
	const [course, setCourse] = useState("");

	const [profileData, setProfileData] = useState([]);
	const [newsBlink, setNewsBlink] = useState(false);
	const [newsData, setNewsData] = useState([]);

	const [otp, setOtp] = useState("");
	const [passChange, setPassChange] = useState(false);

	const [registerActive, setRegisterActive] = useState(false);
	const [verifyEmailOtp, setVerifyEmailOtp] = useState(false);

	const [clinetLocation, setClinetLocation] = useState({ lat: "", lon: "" });
	// const [currentAffairs, setCurrentAffairs] = useState(fetchCurrentAffairs);
	const [blinkFocus, setBlinkFocus] = useState(false);

	// const [currentAffairsFoucus, setCurrentAffairsFoucus] = useState(false);
	// const encryption = new Encryption();
	const [pack, setPack] = useState([]);
	const [isSeo, setIsSeo] = useState(false);
	const [quiz_id, setquizid] = useState("");
	const [course_id, setcourseid] = useState("");
	const [quiz_name, setName] = useState("");
	const [user_name, setUserName] = useState("");
	const param = window.location.pathname;
	const [filteredCourses, setFilteredCourses] = useState([]);
	const [path, setPath] = useState("");
	const [courses, setCourseDetails] = useState([]);
	const [recClasses, setRecClasses] = useState([]);
	const navigate = useNavigate();
	const [slideIndex, setSlideIndex] = useState(0);

	const [examPattern, setExamPattern] = useState([]);

	const [section, setSection] = useState("");
	const [subject, setSubject] = useState("");
	const isFormValid = user_name && email && mobile;
	const handleLink = (section, subject) => {
		setSubject(subject);
		setSection(section);

		document.getElementById("languageModal").click();
	};

	const langugageData = [
		{
			id: 1,
			title: "Section IA",
			lan: [
				"Assamese",
				"Hindi",
				"Marathi",
				"Tamil",
				"Bengali ",
				"Kannada",
				"Odia",
				"Telegu",
				"English",
				"Malayalam",
				"Punjabi",
				"Urdu",
				"Gujrati",
			],
		},
		{
			id: 2,
			title: "Section IB",
			lan: [
				"Arabic ",
				"German ",
				"Maithili",
				"Bodo  ",
				"Chinese  ",
				"Dogri",
				"French",
				"Italian ",
				"Japanese",
				"Kashmiri ",
				"Konkani",
				"Manipuri ",
				"Nepali ",
				"Persian ",
				"Russian ",
				"Santhali ",
				"Sindhi ",
				"Spanish ",
				"Sanskrit ",
				"Tibetan",
			],
		},

		{
			id: 3,
			title: "Section II",
			lan: [
				"Accountancy / Bookkeeping",
				"Agriculture",
				"Anthropology",
				"Biologyc / Biological Studies / Biotechnology / Biochemistry",
				"Business Studies",
				"Chemistry",
				"Environmental Studies",
				"Computer Science / Informative Practices",
				"Economics / Business Economics",
				"Engineering Graphics",
				"Fine Arts / Visual Arts / Commercial Art",
				"Geography / Geology",
				"History",
				"Home Science",
				"Knowledge Tradition Practices India",
				"Legal Studies",
				"Mass Media / Mass Communication",
				"Mathematics",
				"Performing Arts: i) Dance(Kathak / Bharatnatyam / Kathakali / Odissi / Kuchipudi / Manipuri), ii) Drama, iii) Music(Hindustani / Carnatic / Rabindra Sangeet / Percussion / Non-Percussion)",
				"Physical Education/National Cadet Corps(NCC)/Yoga",
				"Physics",
				"Political Science",
				"Psychology",
				"Sanskrit",
				"Sociology",
				"Teaching Aptitude",
			],
		},

		{
			id: 4,
			title: "Section III",
			lan: [
				"General Knowledge",
				"Current Affairs",
				"General Mental Ability",
				"Numerical Ability",
				"Quantitative Reasoning (Simple Application of Basic Mathematical Concept/Arithmetic/Algebra/Geometry/Mensuration/Statistics taught till class 8) ",
				"Logical and Analytical reasoning",
			],
		},
	];
	const chunklanguage = (ar) => {
		var array = ar[0].lan;
		const perChunk =
			ar[0].title == "Section III"
				? 1
				: ar[0].title == "Section II"
					? 2
					: 4; // items per chunk

		const result = array.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / perChunk);

			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = []; // start a new chunk
			}

			resultArray[chunkIndex].push(item);

			return resultArray;
		}, []);
		return result;
	};
	function onExamPattern(id) {
		fetch(`${baseUrl}/df/examPattern/${id}`, {
			method: "GET",
			headers: {
				Authorization: `${Cookies.get("token")}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setExamPattern(data.Data);
			});
	}

	const handleStartTest = (item) => {
		setPath(param.split("/registration")[1]);

		setquizid(item.quizId);
		setcourseid(item.courseId);
		setName(item.title);
		setIsSeo(true);
		document.getElementById("openquiz").click();
	};
	const onSubmit = (e) => {
		console.log(e);
		e.preventDefault();
		navigate("/StudentExamTestMcq", {
			state: {
				path: path,
				quizId: quiz_id,
				courseId: course_id,
				name: quiz_name,
				user_Name: user_name,
				email: email,
				mobile: mobile,
				seo: isSeo,
			},
		});
		document.getElementById("close_button").click();
	};

	useEffect(() => {
		console.log("call subscription api");
		axios
			.get(
				`${baseUrl}/df/getAllSubscriptionPacks/${1}`,
				{
					method: "GET",
					headers: {
						"Acces-Control-Allow-Origin": "*",
						Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
						// Authorization: `${Cookies.get("token")}`,
					},
				}
			)

			.then((data) => {
				setPack(data.data.Data);
			})
			.catch((e) => console.log(e));
	}, []);

	// useEffect(() => {
	// 	axios
	// 		.post(
	// 			baseUrl + "/df/coursesAndTopics/",
	// 			{
	// 				courseId: "0",
	// 			},
	// 			{
	// 				headers: {
	// 					"Acces-Control-Allow-Origin": "*",
	// 					Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			// setLoading(false);
	// 			if (response.status === 200) {
	// 				setCourseDetails(response.data.Data);
	// 				// window.location.reload();
	// 				console.log(courses);
	// 			} else {
	// 				setCourseDetails([]);
	// 			}
	// 		})
	// 		.catch((e) => {
	// 			console.log(e);
	// 			alert("Please Check Details");
	// 			setCourseDetails([]);
	// 			// setLoading(false);
	// 		});
	// }, []);

	useEffect(() => {
		axios
			.post(
				baseUrl + "/getLiveRecClasses",
				{
					courseId: "1",
				},
				{
					headers: {
						"Acces-Control-Allow-Origin": "*",
						Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
						// Authorization: `${Cookies.get('token')}`,
					},
				}
			)
			.then((response) => {
				console.log(response.data.Data);
				setRecClasses(response.data.Data);
			})
			.catch((e) => console.log(e));
	}, []);
	const [currentAffairs, setCurrentAffairs] = useState([]);
	const config = {
		"Access-Control-Allow-Origin": "*",
		Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${baseUrl}/df/findCurrentAffairs`,
					{
						method: "GET",
						headers: config,
					}
				);
				const data = await response.json();
				if (data.status === 200) setCurrentAffairs(data.result);
			} catch (err) {
				console.log(err);
			}
		};
		fetchData();
	}, []);
	useEffect(() => {
		axios.get(baseUrl + "/df/findNewsEventDtls/1").then((response) => {
			if (response.status === 200) {
				setNewsData(response.data.result);
			}
		});
	}, []);
	useEffect(() => {
		const fetchStudentSpeak = async () => {
			try {
				const config = {
					"Access-Control-Allow-Origin": "*",
					Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
					// Authorization: Cookies.get('token'),
				};

				const { data } = await axios.get(
					`${baseUrl}/df/coursesExplore`,
					{},
					{ headers: config }
				);
				// if (status === 200) setCourseUpdate(data.Data);
				// const temp = [{ courseHead: "", courseSubHead: "", impDateArr: [], impUpdateArr: [], currentAffair: [], reviews: [], courseDetails: "" }];
				setCourseUpdate(data.Data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchStudentSpeak();
	}, []);
	const { ref, inView, entry } = useInView({
		threshold: 0.1,
	});

	const location = {
		address:
			"37, 2nd bye lane, B.R.Mazumdar Path, BAGHORBORI, PANJABARI, GUWAHATI-781037, ASSAM",
		lat: 26.137485,
		lng: -91.824573,
	};

	const componentClicked = () => console.log("clicked");

	// FIXME:
	const failureFacebook = (response) => {
		console.log(response);
	};
	const responseFacebook = (res) => {
		console.log(res.email);

		const body = {};

		const paramsString = window.location.search;
		const searchParams = new URLSearchParams(paramsString);
		// const code = searchParams.get("code");
		const prompt = searchParams.get("prompt");

		navigator.geolocation.getCurrentPosition((position) => {
			setClinetLocation({
				lat: position.coords.latitude.toFixed(2),
				lon: position.coords.longitude.toFixed(2),
			});
		});
		// for Facebook login
		if (prompt === null) {
			axios
				.post(baseUrl + `/wl/extLogin`, {
					email: EncryptText(res.email),
					name: res.name.split(" ")[0],
					source: "Facebook",
					uid: res.userID,
					latitude: clinetLocation.lat,
					longitude: clinetLocation.lon,
				})
				.then((response) => {
					console.log("sucessfully", response);
					if (response.data.result.hasPerDtlsUpdated === false) {
						history("/register", {
							state: {
								profileObj: {
									email: res.email,
									name: res.name.split(" ")[0],
									lastname: res.name.split(" ")[1],
									latitude: clinetLocation.lat,
									longitude: clinetLocation.lon,
									uid: res.userID,
									source: "Facebook",
								},
							},
						});
						const modal = document.querySelector(".modal-backdrop");
						modal.remove();
					} else {
						history("/studentDashboard");
						Cookies.set(
							"token",
							`Bearer ${response.data.result.token}`
						);
						Cookies.set("email", res.email);
						Cookies.set(
							"userId",
							response.data.result.userLoginResBean.userId
						);
					}
				})
				.catch((err) => {
					console.error("Not Login in Facebook");
				});
		}
	};
	useRemoveModal();

	useEffect(() => {
		gapi.load("client:auth2", () => {
			gapi.client.init({
				clientId: clientId,
				scope: "",
			});
		});
	});

	useEffect(() => {
		document.body.style.overflow = "visible";
	}, []);

	useEffect(() => {
		if (Cookies.get("token") !== null) {
			axios
				.post(
					baseUrl + "/profileData",
					{
						email: Cookies.get("email"),
					},
					{
						headers: {
							"Acces-Control-Allow-Origin": "*",
							Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
							Authorization: `${Cookies.get("token")}`,
						},
					}
				)
				.then((response) => {
					if (response.status === 200) {
						setProfileData(response.data.Data);
					}
				});
		}
	}, []);

	useEffect(() => {
		const paramsString = window.location.search;
		const searchParams = new URLSearchParams(paramsString);
		const res = searchParams.get("code");
		const prompt = searchParams.get("prompt");
		const code = decodeURIComponent(res);
		// window.history.pushState({}, document.title, "/");
		console.log(paramsString, res, prompt, code);
		// console.log(window.opener);
		// for google Auth
		if (prompt) {
			// window.top.location = "http://localhost:3000/registration/";
			// post request
			async function tokenCall() {
				try {
					const response = await axios.post(
						`https://accounts.google.com/o/oauth2/token`,
						{
							client_id: process.env.REACT_APP_CLIENT_ID,
							code: code,
							scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
							client_secret: process.env.REACT_APP_CLIENT_SECRET,
							redirect_uri: process.env.REACT_APP_CLIENT_URL,
							grant_type: "authorization_code",
						}
					);
					console.log("response:");
					console.log(response.data.access_token);
					Cookies.set(
						"google_access_token",
						response.data.access_token
					);
					window.history.pushState({}, null, "/registration");

					token();
				} catch (err) {
					console.log(err);
				}
			}
			tokenCall();
		}
	}, []);

	useEffect(() => {
		navigator.geolocation.getCurrentPosition((position) => {
			setClinetLocation({
				lat: position.coords.latitude.toFixed(2),
				lon: position.coords.longitude.toFixed(2),
			});
		});
	}, []);

	function emailValidations() {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			setEmailValidation(true);
		} else {
			if (email.length !== 0) {
				alert("You have entered an invalid email address!");
			}

			setEmailValidation(false);
		}
	}

	// function PasswordChecker() {
	//   if (
	//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
	//       password,
	//     )
	//   ) {
	//     setPassChecker(true)
	//   } else {
	//     setPassChecker(false)
	//   }
	// }

	function formValidation() {
		if (
			email.length > 0 &&
			firstName.length > 0 &&
			lastName.length > 0 &&
			password.length > 0 &&
			verifyEmailOtp &&
			confirmPassword.length > 0 &&
			mobile.length > 0 &&
			whatsappNumber.length > 0 &&
			course &&
			passChecker &&
			password === confirmPassword
		) {
			setRegisterActive((prev) => !prev);
			console.log(registerActive);
		} else {
			alert("Complete all the details first \n Some fields are missing");
		}
	}

	const onClose = () => {
		setVerifyOtp(false);
		setSendOtp(false);
	};
	const [otpSent, setOtpSent] = useState(false);

	function onExamPattern(id) {
		console.log(id, "hhhhhhhhhhhhhhs");
		onExamPattern(1);

		fetch(`${baseUrl}/df/examPattern/${id}`, {
			method: "GET",
			headers: {
				Authorization: `${Cookies.get("token")}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setExamPattern(data.Data);
				console.log(setExamPattern, "hhhhhhhhhhhhhhs");
			});
	}
	useEffect(() => {
		console.log(profileData, "hhhhhhhhhhhhhhs");

		if (profileData?.courseBeans?.length) {
			onExamPattern(profileData?.courseBeans[0].courseId);
		}
	}, []);

	const onRegister = () => {
		setRegisterActive(true);
		setLoading(true);
		axios
			.post(
				`${baseUrl}/df/userRegDetails/`,
				{
					title: "Registration",
					firstName: firstName,
					lastName: lastName,
					email: EncryptText(email),
					password: EncryptText(password),
					number: EncryptText(mobile),
					whatsappNumber: EncryptText(whatsappNumber),
					course: JSON.parse(course),
				},
				{
					headers: {
						"Access-Control-Allow-Origin": "*",
						Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
					},
				}
			)
			.then((response) => {
				setLoading(false);
				setRegisterSuccess("green");
				setRegisterError(response.data.ResultMessage);
				if (response.data.ResultCode != 200) {
					setRegisterSuccess("red");
				} else {
					setRegisterSuccess("green");
				}
				if (response.status === 200) {
					document.querySelector("#openSuccessRegister").click();
				}
				if (response.data.ResultCode === 200) {
					document.querySelector("#openSuccessRegister").click();

					document
						.getElementById("registerModal")
						.classList.remove("show");
					// window.location.reload()
				} else {
					// alert(response.data.ResultMessage);
				}
			})
			.catch((e) => {
				alert(e);
				setLoading(false);
			});
	};
	useEffect(() => {
		axios
			.post(
				baseUrl + "/df/coursesAndTopics/",
				{
					courseId: "0",
				},
				{
					headers: {
						"Acces-Control-Allow-Origin": "*",
						Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
					},
				}
			)
			.then((response) => {
				setLoading(false);
				if (response.status === 200) {
					console.log("items", response.data);
					setCourseDetails(response.data.Data);
					// window.location.reload()
				} else {
					setCourseDetails([]);
				}
			})
			.catch((e) => {
				console.log(e);
				alert("Please Check Details");
				setCourseDetails([]);
				setLoading(false);
			});
	}, []);
	const onRegisterClick = useCallback(() => {
		setLoading(true);
		axios
			.post(
				baseUrl + "/df/coursesAndTopics/",
				{
					courseId: "1",
				},
				{
					headers: {
						"Acces-Control-Allow-Origin": "*",
						Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
					},
				}
			)
			.then((response) => {
				setLoading(false);
				if (response.status === 200) {
					console.log("items", response.data);
					setCourseDetails(response.data.Data);
					// window.location.reload()
				} else {
					setCourseDetails([]);
				}
			})
			.catch((e) => {
				console.log(e);
				alert("Please Check Details");
				setCourseDetails([]);
				setLoading(false);
			});
	}, []);

	const onLogin = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(baseUrl + "/wl/loginDtls", {
				username: EncryptText(email),
				password: EncryptText(password),
			})
			.then((response) => {
				console.log(response.status);
				console.log(response.data.status);
				console.log(
					"response",
					response.data.result.userLoginResBean.email
				);
				if (response.status == 200) {
					Cookies.set(
						"token",
						`Bearer ${response.data.result.token}`
					);
					Cookies.set(
						"email",
						response.data.result.userLoginResBean.email
					);
					Cookies.set(
						"userId",
						response.data.result.userLoginResBean.userId
					);
					// alert(response.data.message);
					setLoading(false);
					// document.getElementById("loginModal").style.display = "none";
					history("/studentDashboard");
					const modal = document.querySelector(".modal-open");

					// document.querySelector("body").classList.remove("modal-open");
					// document.querySelector("body").style.overflow = "visible";
					// modal.style.display = "none";
					modal.remove();
					window.location.reload();
				}
			})
			.catch((e) => {
				setLoading(false);
				setPassword("");
				alert("Invalid login Details");
			});
	};

	const onSendOtp = (id) => {
		setOtpSent(true);
		axios
			.post(baseUrl + `/wl/sendOTP`, {
				EmailId: EncryptText(email),
				OtpType: `${id}`,
			})
			.then((response) => {
				console.log("res", response.data.ResultCode);
				if (
					response.status == "200" &&
					response.data.ResultCode == "200"
				) {
					setSendOtp(true);
					setOtpBox(true);
				} else {
					alert(response.data.ResultMessage);
					setEmailSendStatusMsg(response.data.ResultMessage);
				}
			});
	};

	const onVerify = () => {
		setLoading(true);
		axios
			.post(baseUrl + `/wl/verifyOTP`, {
				EmailId: EncryptText(email),
				Otp: EncryptText(otp),
			})
			.then((response) => {
				if (response.data.ResultCode === "200") {
					setLoading(false);
					setEmailVerify(true);
					setVerifyOtp(true);
					setSendOtp(false);
				} else {
					setLoading(false);
					alert(response.data.ResultMessage);
				}
			})
			.catch((e) => {
				setLoading(false);
				alert(e);
			});
	};

	const onPasswordChange = () => {
		axios
			.post(baseUrl + `/wl/forgetPassword`, {
				EmailId: EncryptText(email),
				Password: EncryptText(password),
			})
			.then((response) => {
				if (response.data.ResultCode === "200") {
					setMessage("Your Password has changed SuccessFully!!");
					setPassChange(true);

					document.querySelector("#particular-login").click();
					setVerifyOtp(false);
					setSendOtp(false);
					setTimeout(() => {
						setMessage("");
					}, 3000);
				} else {
					alert(response.data.ResultMessage);
				}
			});
	};
	const [studentSpeak, setStudentSpeak] = useState([]);

	useEffect(() => {
		console.log("selectedCourse", selectedCourse);
		// Filter the courses based on the selected course
		if (selectedCourse) {
			const filteredData = couresData.filter(item => item.courseId == selectedCourse);
			setFilteredCourses(filteredData);
		} else {
			// If no course selected, show all courses
			setFilteredCourses(couresData);
		}
	}, [selectedCourse, couresData]);

	useEffect(() => {
		const fetchStudentSpeak = async () => {
			try {
				const config = {
					"Access-Control-Allow-Origin": "*",
					Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
					Authorization: Cookies.get("token"),
				};

				const { data, status } = await axios.post(
					`${baseUrl}/df/studentSpeak`,
					{},
					{ headers: config }
				);
				if (status === 200) setStudentSpeak(data.Data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchStudentSpeak();
	}, []);
	const handleDisabled = () => {
		if (mobile && email) {
			return false;
		}
		return true;
	};
	const authHandler = async () => {
		// let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
		// width=500,height=600,left=500,top=500`;
		let params = "";
		let open = "_self";
		window.open(
			`https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CLIENT_URL}&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&response_type=code`,
			open,
			params
		);
		// console.log(popup);

		window.onmessage = async (message) => {
			console.log("message", message);
			window.location.reload();
		};
	};

	async function token() {
		if (Cookies.get("google_access_token") != null) {
			// Cookies.set("google_access_token", response.data.access_token);
			if (Cookies.get("google_access_token")) {
				const res = await axios.get(
					"https://people.googleapis.com/v1/people/me?personFields=emailAddresses,names",
					{
						headers: {
							Authorization: `Bearer ${Cookies.get(
								"google_access_token"
							)}`,
						},
					}
				);
				console.log("Login success>>");
				console.log(res);
				console.log(res.data);
				if (res.data) Cookies.set("google_access_token", null);

				onLoginSuccess(res.data);
			}
		}
	}

	// FIXME:
	const onLoginSuccess = (res) => {
		console.log("Login Success:", res);

		const body = {
			email: res.emailAddresses[0].value,
			name: res.names[0].givenName,
			lastname: res.names[0].familyName,
			source: "Google",
			uid: res.resourceName,
			latitude: clinetLocation.lat,
			longitude: clinetLocation.lon,
		};
		// console.log(res.names[0].givenName);

		navigator.geolocation.getCurrentPosition((position) => {
			setClinetLocation({
				lat: position.coords.latitude.toFixed(2),
				lon: position.coords.longitude.toFixed(2),
			});
		});

		axios
			.post(baseUrl + `/wl/extLogin`, {
				email: EncryptText(res.emailAddresses[0].value),
				name: res.names[0].givenName,
				source: "Google",
				uid: res.resourceName.split("/")[1],
				latitude: clinetLocation.lat,
				longitude: clinetLocation.lon,
			})
			.then((response) => {
				console.log("sucessfully  Google Response", response);
				if (response.data.result.hasPerDtlsUpdated === false) {
					history("/register", {
						state: {
							profileObj: {
								email: res.emailAddresses[0].value,
								name: res.names[0].givenName,
								lastname: res.names[0].familyName,
								latitude: clinetLocation.lat,
								longitude: clinetLocation.lon,
								uid: res.resourceName.split("/")[1],
								source: "Google",
							},
							res: res,
						},
					});
					const modal = document.querySelector(".modal-backdrop");
					modal.remove();
				} else {
					history("/studentDashboard");
					Cookies.set(
						"token",
						`Bearer ${response.data.result.token}`
					);
					Cookies.set("email", res.emailAddresses[0].value);
					Cookies.set(
						"userId",
						response.data.result.userLoginResBean.userId
					);
					// window.location.reload();
				}
			})
			.catch((err) => {
				console.error("Not Login in Google");
			});
	};

	const onLoginFailure = (res) => {
		console.log("Login Failed:", res);
	};

	function animateValue(obj, start, end, duration) {
		let startTimestamp = null;
		const step = (timestamp) => {
			if (!startTimestamp) startTimestamp = timestamp;
			const progress = Math.min(
				(timestamp - startTimestamp) / duration,
				1
			);
			obj.innerHTML = Math.floor(progress * (end - start) + start);
			if (progress < 1) {
				window.requestAnimationFrame(step);
			}
		};
		window.requestAnimationFrame(step);
	}

	const handleOnlyLetters = (event, name) => {
		const result = event.target.value.replace(/[^a-z]/gi, "");

		if (name === "first") {
			setFirstName(result);
		} else if (name === "last") {
			setLastName(result);
		}
	};

	//
	function showFaqAns(targetId) {
		const targetModal = document.getElementById(targetId);
		targetModal.style.display = "block";
	}

	function hideFaqAns(targetId) {
		const targetModal = document.getElementById(targetId);
		targetModal.style.display = "none";
	}

	const [currentAffairsFlag, setCurrentAffairsFlag] = useState(false);
	const [currentAffairsFoucus, setCurrentAffairsFoucus] = useState(false);

	useEffect(() => {
		axios.get(baseUrl + "/df/findNewsEventDtls/1").then((response) => {
			if (response.status === 200) {
				setNewsData(response.data.result);

				setNewsBlink(response.data.newsToBlink);
				// setNewsBlink(true);
			}
		});
	}, []);

	useEffect(() => {
		fetch(`${baseUrl}/df/findCurrentAffairs`, {
			method: "GET",
			headers: {
				"Access-Control-Allow-Origin": "*",
				Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				console.log("findCurrentAffairs", data.result);
				if (data.status === 200) {
					console.log(data.newsToBlink, ">>>>>>>>>>>>");
					setCurrentAffairsFlag(data.newsToBlink);
					// setCurrentAffairsFlag(true);
				}
			});
	}, []);

	useEffect(() => {
		let timer;
		if (currentAffairsFoucus) {
			timer = setTimeout(() => {
				setCurrentAffairsFoucus(false);
				setCurrentAffairsFlag(false);
				setNewsBlink(false);
			}, 5000);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [currentAffairsFoucus]);

	useEffect(() => {
		let timer;
		setCurrentAffairsFoucus(false);
		if (currentAffairsFlag) {
			timer = setTimeout(() => {
				setCurrentAffairsFoucus(true);
				console.log("Foucs BLink ACtive");
			}, 6000);
		} else setCurrentAffairsFoucus(false);

		return () => {
			clearTimeout(timer);
		};
	}, [currentAffairsFlag]);

	useEffect(() => {
		axios
			.post(baseUrl + "/df/getAllFreeQuizByCourse", {
				courseId: 1,
			})
			.then((response) => {
				if (response.status === 200) {
					const data = response.data.Data;
					console.log(data, "res....");
					setCourseData(data);
				}
			});
	}, []);

	const data = [
		{
			id: 1,
			url: `https://cdnasb.samarth.ac.in/site/CUETUG2022IB.pdf`,
			title: "  Information Bulletin",
		},
		{
			id: 2,
			url: `https://cuet.samarth.ac.in/index.php/site/syllabus`,
			title: "   Syllabus",
		},
		{
			id: 3,
			url: `https://cuet.samarth.ac.in/index.php/app/info/universities`,
			title: " Universities",
		},
		{
			id: 4,
			url: `https://cdnasb.samarth.ac.in/site/CUET2022ApplicationGuide.pdf`,
			title: " Application Guide",
		},
		{
			id: 5,
			url: `https://cdnasb.samarth.ac.in/site/FAQCUET2022.pdf`,
			title: "  FAQ’s",
		},
		{
			id: 6,
			url: `https://cuet.samarth.ac.in/index.php/site/contact`,
			title: " Contact us",
		},
	];
	const handleClose = () => {
		setFirstName("");
		setEmail("");

		setMobile("");

		// setAgree(false)
		setPassword("");
		setConfirmPassword("");
	};
	const handleClick = (item) => {
		Swal.fire({
			title: "You are being redirected to an external website.",
			showCancelButton: true,
			confirmButtonText: "OK",
			denyButtonText: `Cancle`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				window.open(`${item}`, "_blank");

				// Swal.fire("Saved!", "", "success");
			} else if (result.isDenied) {
				Swal.fire("Changes are not saved", "", "info");
			}
		});
	};
	const [ContactUs, setContactUs] = useState("");

	useEffect(() => {
		fetch(`${baseUrl}/df/getContactSupport`, {
			method: "GET",
			headers: {
				"Acces-Control-Allow-Origin": "*",
				Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
			},
		})
			.then((response) => response.json())
			.then((result) => {
				{
					console.log("day", result);
				}
				if (result.ResultCode === "200") {
					{
						console.log("day", result.Data);
					}
					setContactUs(result.Data[0]);
					// setFaqQuestions(result.Data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);
	useEffect(() => {
		const fetchStudentSpeak = async () => {
			try {
				const config = {
					"Access-Control-Allow-Origin": "*",
					Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
					Authorization: Cookies.get("token"),
				};

				const { data } = await axios.get(
					`${baseUrl}/df/coursesExplore`,
					{},
					{ headers: config }
				);
				// if (status === 200) setCourseUpdate(data.Data);
				// const temp = [{ courseHead: "", courseSubHead: "", impDateArr: [], impUpdateArr: [], currentAffair: [], reviews: [], courseDetails: "" }];
				setCourseUpdate(data.Data);
			} catch (error) {
				console.log(error);
			}
		};
		fetchStudentSpeak();
	}, []);
	const styles = {
		container: { paddingTop: "6.25rem", paddingBottom: "6.25rem" },
		video: { width: "100%", height: "100%" },
	};

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<meta
					name='keyword'
					content='Best online platform for current affairs in India'
				/>
				<meta
					name='description'
					content='Looking for the Best Online Platform for Current Affairs in India? BESST has got you covered! Join now and stay up-to-date on current affairs in India. Get access to comprehensive and reliable news, analysis, and insights on the latest happenings in politics, economy, society, and more. '
				/>
				<title>Best Online Platform for Current Affairs in India</title>
				<link
					rel='canonical'
					href='https://besst.in/registration/blog/2023/dynamic/best-online-platform-for-current-affairs-in-India'
				/>
			</Helmet>

			<section
				className='container-fluid bg-white'
				style={{ padding: 0, marginTop: "89px" }}
			>
				<RegistrationModal
					name={firstName}
					email={email}
					mobile={mobile}
					password={password}
					confirm={confirmPassword}
					close={handleClose}
				/>

				<div
					class='modal fade'
					id='ReviewsModal'
					aria-labelledby='#ReviewsModal'
					tabIndex='-1'
					aria-hidden='true'
				>
					<div class='modal-dialog modal-lg modal-dialog-scrollable'>
						<div class='modal-content'>
							<div class='modal-header'>
								Reviews Feedback & Suggestion of BESST
								<button
									type='button'
									data-dismiss='modal'
									class='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>
							<div class='modal-body'>
								<div
									className='d-flex text-center gap-2 justify-content-evenly'
									style={{ flexWrap: "wrap" }}
								>
									{studentSpeak.map((item, i) => (
										<div
											key={i}
											className='bg-white w-100 mx-3 border rounded overflow-hidden'
										>
											<div className='ratio ratio-16x9'>
												<iframe
													src={item.videoUrl}
													title={item?.name}
													style={styles.video}
													className='yb-player-icon'
													frameborder='0'
												></iframe>
											</div>
											<div className='p-4 fw-bolder'>
												<div>{item?.name}</div>
												<div>{item.textContent}</div>
											</div>
										</div>
									))}
								</div>
							</div>
							<div
								className='modal-footer justify-content-center '
								style={{
									fontWeight: "800",
									fontSize: "20px",
								}}
							>
								<a
									href='https://www.youtube.com/channel/UCOWq3a0_HYLFYj7ZqWAjSeA'
									target='_blank'
								>
									For More Videos Please Visit&#160;
									<ImYoutube2 size={55} color='red' /> Channel
								</a>
							</div>
						</div>
					</div>
				</div>
				<div
					className='modal fade'
					id='classNameModal'
					tabIndex='-1'
					aria-labelledby='classNameModalLabel'
					aria-hidden='true'
				>
					<div className='modal-dialog modal-dialog-scrollable downloadModal'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5
									className='modal-title main-color'
									id='classNameModalLabel'
								>
									Recorded Live Classes
								</h5>
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								// style={{ zIndex: 999 }}
								></button>
							</div>
							<div className='modal-body'>
								<div style={{ textAlign: "left" }}>
									<div
										className='row'
										style={{
											paddingBottom: "3%",
											paddingLeft: "5%",
										}}
									>
										{console.log(recClasses, "recClasses")}

										{recClasses.map((items, index) => (
											<VideoData
												url={items.videoUrl}
												title={items.videoTitle}
												key={index}
											/>
										))}
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<button
					id='openquiz'
					data-bs-toggle='modal'
					data-bs-target='#quizModal'
					style={{ display: "none" }}
				></button>
				<div
					className='modal fade'
					id='quizModal'
					tabIndex='-1'
					aria-labelledby='loginModalLabel'
					aria-hidden='true'
				>
					<div className='modal-dialog'>
						<div className='modal-content ml-0'>
							<div
								className='modal-header text-center'
								style={{ borderBottom: "none" }}
							>
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>
							<h5
								className='modal-title text-center'
								id='QuizModalLabel'
							>
								Please enter following details review your{" "}
								<br /> <p style={{ color: "purple" }}>{name}</p>{" "}
							</h5>
							<div className=''>
								<form
									style={{ border: "none", padding: "10px" }}
									onSubmit={onSubmit}
								>
									<div className='input-group mb-3'>
										<input
											style={{
												padding: "14px",
												backgroundColor: "#FCFCFC",
												fontWaight: 400,
												fontSize: "16px",
												lineHeight: "22px",
											}}
											id='name'
											type='text'
											className='form-control'
											placeholder='Name'
											required
											onChange={(e) =>
												setUserName(e.target.value)
											}
										/>
									</div>

									<div className='input-group mb-3'>
										<input
											style={{
												padding: "14px",
												backgroundColor: "#FCFCFC",
												fontWaight: 400,
												fontSize: "16px",
												lineHeight: "22px",
											}}
											id='email'
											type='email'
											className='form-control'
											placeholder='Email'
											required
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>
									<div className='input-group mb-3'>
										<input
											style={{
												padding: "14px",
												backgroundColor: "#FCFCFC",
												fontWaight: 400,
												fontSize: "16px",
												lineHeight: "22px",
											}}
											id='mobile'
											type='number'
											required
											className='form-control'
											placeholder='Mobile No'
											onChange={(e) =>
												setMobile(e.target.value)
											}
										/>
									</div>

									<button
										type='submit'
										className='btn main-btn'
										style={{ width: "100%" }}
										data-bs-dismiss='modal'
										disabled={!isFormValid}
									>
										{loading ? "Please Wait.." : "CONFIRM"}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div
					className='modal fade'
					id='loginModal'
					tabIndex='-1'
					aria-labelledby='loginModalLabel'
					aria-hidden='true'
				>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5
									className='modal-title'
									id='loginModalLabel'
								>
									Login
								</h5>
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>
							<div className='modal-body mx-auto'>
								<form>
									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span
												className='input-group-text'
												id='basic-addon1'
											>
												<i className='fa-solid fa-at main-color'></i>
											</span>
										</div>
										<input
											id='email'
											type='text'
											className='form-control'
											placeholder='Email'
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>
									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span
												className='input-group-text'
												id='basic-addon1'
											>
												<i className='fa-solid fa-key main-color'></i>
											</span>
										</div>
										<input
											id='lastName'
											type={
												isPassed ? "text" : "password"
											}
											className='form-control'
											placeholder='Password'
											onChange={(e) =>
												setPassword(e.target.value)
											}
										/>{" "}
										{isPassed ? (
											<AiFillEyeInvisible
												style={{
													fontSize: "22px",
													marginLeft: "5px",
													marginTop: "5px",
												}}
												onClick={(e) => {
													setIsPassed(false);
												}}
											/>
										) : (
											<AiFillEye
												style={{
													fontSize: "22px",
													marginLeft: "5px",
													marginTop: "5px",
												}}
												onClick={(e) => {
													setIsPassed(true);
												}}
											/>
										)}
									</div>

									<div
										className='text-center'
										style={{
											marginTop: "-1rem",
										}}
									>
										<a
											style={{
												fontSize: "10px",
											}}
											data-bs-toggle='modal'
											data-bs-target='#forgotpassword'

										>
											Forgot Password?
										</a>
									</div>
									<div
										className='text-center'
										style={{
											marginTop: "3px",
										}}
									>
										<a
											style={{
												fontSize: "10px",
											}}
											data-bs-toggle='modal'
											data-bs-target='#registerModal'

											onClick={() => onRegisterClick()}
										>
											Not registered? Signup here...
										</a>
									</div>

									<button
										type='submit'
										className='btn main-btn '
										data-mdb-dismiss='modal'
										onClick={onLogin}
									>
										{loading ? "Please Wait.." : "Login"}
									</button>
									{passChange && (
										<p
											style={{
												fontSize: "12px",
												color: "green",
												margin: "10px 0 0 0",
											}}
										>
											{message}
										</p>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
				<div
					className='modal fade'
					id='loginModal'
					tabIndex='-1'
					aria-labelledby='loginModalLabel'
					aria-hidden='true'
				>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5
									className='modal-title'
									id='loginModalLabel'
								>
									Login
								</h5>
								<button
									type='button'
									className='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>
							<div className='modal-body mx-auto'>
								<form>
									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span
												className='input-group-text'
												id='basic-addon1'
											>
												<i className='fa-solid fa-at main-color'></i>
											</span>
										</div>
										<input
											id='email'
											type='text'
											className='form-control'
											placeholder='Email'
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>

									<div className='input-group mb-3'>
										<div className='input-group-prepend'>
											<span
												className='input-group-text'
												id='basic-addon1'
											>
												<i className='fa-solid fa-key main-color'></i>
											</span>
										</div>
										<input
											id='lastName'
											type='password'
											className='form-control'
											placeholder='Password'
											onChange={(e) =>
												setPassword(e.target.value)
											}
										/>
									</div>
									<div
										className='text-center'
										style={{
											marginTop: "-1rem",
										}}
									>
										<a
											style={{
												fontSize: "10px",
											}}
											data-bs-toggle='modal'
											data-bs-target='#forgotpassword'

										>
											Forgot Password?
										</a>
									</div>
									<div
										className='text-center'
										style={{
											marginTop: "3px",
										}}
									>
										<a
											style={{
												fontSize: "10px",
											}}
											data-bs-toggle='modal'
											data-bs-target='#registerModal'

											onClick={() => onRegisterClick()}
										>
											Not registered? Signup here...
										</a>
									</div>

									<div className='m-3 text-start d-flex justify-content-around'>
										<>
											<FacebookLogin
												appId={
													process.env
														.REACT_APP_FACEBOOK_CLIENT_ID
												}
												autoLoad={false}
												fields='name,email,picture'
												onClick={componentClicked}
												callback={responseFacebook}
												onFailure={failureFacebook}
												icon='fa-facebook'
												cssClass='btnFacebook'
											/>
										</>
										<button
											type='button'
											id='google-btn'
											style={{
												backgroundColor:
													"rgba(255,255,255)",
												border: "0px solid ",
											}}
											onClick={() => authHandler()}
										>
											<div class='google-btn'>
												<div class='google-icon-wrapper'>
													<div
														style={{
															position:
																"relative",
															height: "100%",
														}}
													>
														<img
															class='google-icon'
															src={google_icon}
														/>
													</div>
												</div>
											</div>
										</button>
									</div>
									<button
										type='submit'
										className='btn main-btn '
										data-mdb-dismiss='modal'
										onClick={onLogin}
									>
										{loading ? "Please Wait.." : "Login"}
									</button>

									{passChange && (
										<p
											style={{
												fontSize: "12px",
												color: "green",
												margin: "10px 0 0 0",
											}}
										>
											{message}
										</p>
									)}
								</form>
							</div>
						</div>
					</div>
				</div>
				<div
					class='modal fade '
					id='subscriptionModal'
					tabIndex='-1'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'
				>
					<div
						class='modal-dialog modal-lg'
						style={{
							maxWidth: "100%",
							margin: "100px",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							height: "90vh",
							display: "flex",
						}}
					>
						<div class='modal-content'>
							<div class='modal-header'>
								<button
									type='button'
									class='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'
								></button>
							</div>
							<div
								class='modal-body'
								style={{ overflowX: "scroll" }}
							>
								<div id='generic_price_table'>
									<section className='gate'>
										<div className='container'>
											<div
												className='row no-gutters'
												style={{
													display: "flex",
													position: "relative",
													gap: "10px",
												}}
											>
												<div className='col-md-12'>
													<h3 className='text-center subscription_head'>
														Subscription Packs
													</h3>
													<p className='text-center subscription_txt'>
														View our range of Packs
														available with different
														features
													</p>
												</div>
												<Swiper
													allowTouchMove={false}
													spaceBetween={10}
													loop={true}
													loopFillGroupWithBlank={
														true
													}
													longSwipes={false}
													breakpoints={{
														// when window width is <= 499px
														560: {
															slidesPerView: 1,
															spaceBetweenSlides: 50,
														},

														880: {
															slidesPerView: 3,
															spaceBetweenSlides: 50,
														},
														// when window width is <= 999px
														999: {
															slidesPerView: 3,
															spaceBetweenSlides: 50,
														},
														1165: {
															slidesPerView: 3,
															spaceBetweenSlides: 50,
														},
													}}
													navigation={true}
													modules={[
														Pagination,
														Navigation,
													]}
													onSlideChange={() => {
														setSlideIndex(
															slideIndex + 1
														);
													}}
													onSwiper={(swiper) =>
														console.log(swiper)
													}
													className='custom-slider'
												>
													{pack?.map(
														(item, index) => {
															console.log(
																item,
																"ITEMS>>"
															);
															let domainCount1 =
																item.avlDomainSubjects?.filter(
																	(x) =>
																		x.specialInstruction ===
																		true
																).length;
															let langCount1 =
																item.avlLangSubjects?.filter(
																	(x) =>
																		x.specialInstruction ===
																		true
																).length;
															let selLang =
																item.avlLangSubjects?.filter(
																	(x) =>
																		x.selection ===
																		true
																).length;
															let selDomain =
																item.avlDomainSubjects?.filter(
																	(x) =>
																		x.selection ===
																		true
																).length;

															let domainSub1 = [];
															item.avlDomainSubjects?.filter(
																(x) => {
																	if (
																		x.specialInstruction ||
																		x.selection
																	) {
																		domainSub1.push(
																			x.topicName
																		);
																		return x.topicName;
																	}
																}
															);

															return (
																<SwiperSlide>
																	<div
																		key={
																			index
																		}
																		className=' card_pack'
																	>
																		<div className='card_body pt-5'>
																			{item.tagLine && (
																				<div class='ribbon-pop'>
																					{
																						item.tagLine
																					}
																				</div>
																			)}
																			<div className='card_title'>
																				<p>
																					{
																						item.subscriptionName
																					}
																				</p>
																			</div>
																			<div className='price_chart'>
																				<p>
																					<span>
																						{
																							item.actualPriceLbl
																						}
																					</span>
																					<span>
																						<strike>
																							Rs.{" "}
																							{
																								item.actualPrice
																							}
																						</strike>
																					</span>
																					<br />
																					<span className='discount_col'>
																						{" "}
																						{
																							item.discount
																						}

																						%
																						off{" "}
																					</span>
																					<span>
																						{item.discountedPriceLbl +
																							item.discountedPrice}
																					</span>
																					<br />

																					(
																					{item.packValidityLbl +
																						" " +
																						item.packValidity}

																					)
																				</p>
																			</div>
																			<div className='pack_inclusion'>
																				<p>
																					{
																						item.packInclusionLbl
																					}
																				</p>
																				<ul>
																					{item.packInclusions.map(
																						(
																							pack,
																							index
																						) => {
																							if (
																								index <
																								2
																							) {
																								return (
																									<li>
																										<div className='pack_img'>
																											<svg
																												width='12'
																												height='13'
																												viewBox='0 0 12 13'
																												fill='none'
																												xmlns='http://www.w3.org/2000/svg'
																											>
																												<path
																													fill-rule='evenodd'
																													clip-rule='evenodd'
																													d='M5.45868 0.514199C4.29978 0.627727 3.22869 1.05491 2.28957 1.77812C1.83224 2.1303 1.23834 2.79217 0.895123 3.33209C0.746898 3.56527 0.45836 4.15615 0.354997 4.43814C0.239427 4.75346 0.116251 5.23532 0.0556371 5.60922C-0.0185457 6.06685 -0.0185457 6.93573 0.0556371 7.39336C0.160526 8.0403 0.326451 8.56329 0.610458 9.14229C0.920758 9.77484 1.24341 10.2232 1.76008 10.7399C2.27678 11.2566 2.72516 11.5792 3.35771 11.8895C3.93671 12.1735 4.4597 12.3395 5.10664 12.4444C5.56427 12.5185 6.43315 12.5185 6.89078 12.4444C7.53772 12.3395 8.06071 12.1735 8.63971 11.8895C9.27226 11.5792 9.72064 11.2566 10.2373 10.7399C10.754 10.2232 11.0767 9.77484 11.387 9.14229C11.5931 8.72213 11.6967 8.44676 11.8078 8.0245C11.9556 7.46217 11.9931 7.15391 11.9931 6.50129C11.9931 5.84867 11.9556 5.54041 11.8078 4.97808C11.6967 4.55582 11.5931 4.28045 11.387 3.86029C11.0767 3.22774 10.754 2.77936 10.2373 2.26266C9.72064 1.74599 9.27226 1.42334 8.63971 1.11304C8.06127 0.829288 7.52793 0.660006 6.90252 0.56162C6.55745 0.507345 5.78694 0.482038 5.45868 0.514199ZM9.14312 3.92428C9.26054 3.96034 9.43969 4.13793 9.48922 4.26743C9.54662 4.41746 9.54087 4.61235 9.47504 4.74832C9.40116 4.90094 5.53685 8.73663 5.36168 8.83124C5.19397 8.92181 4.9959 8.92181 4.82794 8.83124C4.72849 8.77762 2.94851 7.1834 2.64588 6.8769C2.52773 6.75724 2.46895 6.61111 2.47113 6.4426C2.47578 6.0873 2.73486 5.84766 3.08704 5.87285C3.17155 5.87891 3.27454 5.90135 3.31588 5.92271C3.37839 5.95504 4.56513 7.02188 4.94851 7.3904L5.06037 7.4979L6.81481 5.74601C7.77978 4.78248 8.60328 3.97713 8.64483 3.95635C8.80749 3.87496 8.95217 3.86564 9.14312 3.92428Z'
																													fill='#008B38'
																												/>
																											</svg>
																										</div>
																										{
																											pack
																										}
																									</li>
																								);
																							} else if (
																								index ===
																								2
																							) {
																								return (
																									<li className='features'>
																										<Link
																											to=''
																											onClick={() =>
																												setPackInc(
																													item.packInclusions
																												) +
																												setTitle(
																													item.packInclusionLbl
																												)
																											}
																											data-bs-toggle='modal'
																											data-bs-target='#subModal'
																										>
																											View
																											More
																										</Link>
																									</li>
																								);
																							}
																						}
																					)}
																				</ul>
																			</div>

																			{item.avlDomainSubjectsLbl && (
																				<button
																					className='outline-btn mt-4'
																					onClick={() =>
																						showModal(
																							item.subscriptionId,
																							item.avlDomainSubjectsLbl,
																							"showDomain"
																						)
																					}
																					data-bs-toggle='modal'
																					data-bs-target='#subModal'
																				>
																					Domain
																					Subjects
																				</button>
																			)}
																			{item
																				.avlLangSubjects
																				?.length && (
																					<button
																						className='outline-btn mt-3'
																						onClick={() =>
																							showModal(
																								item.subscriptionId,
																								item.avlLangSubjectsLbl,
																								"showLang"
																							)
																						}
																						data-bs-toggle='modal'
																						data-bs-target='#subModal'
																					>
																						Select
																						Languages
																					</button>
																				)}

																			{domainCount1 ||
																				(selDomain &&
																					item.subscribedFlag) ? (
																				<>
																					<p className='mt-3 mb-1'>
																						<strong>
																							Subjects
																							Opted:
																						</strong>
																					</p>
																					<p>
																						{domainSub1.join(
																							", "
																						)}
																					</p>
																				</>
																			) : (
																				""
																			)}

																			{langCount1 ||
																				(selLang &&
																					item.subscribedFlag) ? (
																				<>
																					<p className='mt-3 mb-1'>
																						<strong>
																							Languages
																							Opted:
																						</strong>
																					</p>
																					<p>
																						{item.avlLangSubjects.map(
																							(
																								domain,
																								key
																							) => (
																								<span>
																									{domain.specialInstruction ||
																										domain.selection
																										? domain.topicName
																										: " "}
																								</span>
																							)
																						)}
																					</p>
																				</>
																			) : (
																				""
																			)}
																		</div>
																		{item.subscriptionName ===
																			"Free Pack" ? (
																			<div className='card_footer justify-content-center'>
																				<p className='m-0'>
																					ENJOY
																					FREE
																					CLASSES!
																				</p>
																			</div>
																		) : (
																			<div className='card_footer justify-content-between'>
																				<div className='text-start'>
																					<span>
																						<BiRupee />
																						<strike>
																							{domainCount1 &&
																								item.defaultDomainSubjCount ===
																								1
																								? parseFloat(
																									item.actualPrice
																								) *
																								parseFloat(
																									domainCount1
																								)
																								: item.actualPrice}
																						</strike>
																						<span className='discount_col'>
																							{" "}
																							{
																								item.discount
																							}

																							%
																							off
																						</span>
																					</span>

																					{item.subscriptionId ===
																						2 &&
																						item.defaultDomainSubjCount ? (
																						domainCount1 ||
																							langCount1 ? (
																							<p className='mb-0'>
																								<span>
																									{Math.trunc(
																										item.discountedPrice
																									)}{" "}
																									x{" "}
																									{
																										domainCount1
																									}
																									{langCount1
																										? "+ " +
																										Math.trunc(
																											item.discountedPricePerAddOnSubj
																										) +
																										" x " +
																										langCount1
																										: ""}{" "}
																									=
																									<BiRupee />
																									{Math.trunc(
																										item.discountedPrice
																									) *
																										parseFloat(
																											domainCount1
																										) +
																										Math.trunc(
																											item.discountedPricePerAddOnSubj
																										) *
																										parseFloat(
																											langCount1
																										)}
																								</span>
																							</p>
																						) : (
																							<p className='mb-0'>
																								<BiRupee />{" "}
																								{
																									item.discountedPrice
																								}
																							</p>
																						)
																					) : (
																						<p className='mb-0'>
																							<BiRupee />{" "}
																							{
																								item.discountedPrice
																							}
																						</p>
																					)}
																				</div>
																				<button
																					data-bs-toggle='modal'
																					data-bs-target='#loginModal'
																					className='btn main-btn'
																				>
																					{item.allowSubscription
																						? item.btnText
																						: item.subsLockMsg}
																				</button>
																			</div>
																		)}
																	</div>
																</SwiperSlide>
															);
														}
													)}
												</Swiper>
											</div>
										</div>
									</section>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='row no-gutters'>
					<div className='col-md-12'>
						<div className=' mx-md-5 mt-3 px-5 pt-5 free-mock-test '>
							<div
								className=''
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h1 className='heading mb-4' style={{ fontSize: "26px" }}>Free Mock Tests</h1>
								</div>
							</div>
							<div className="select-course-form my-3 w-100">
								<label style={{ whiteSpace: "nowrap", fontWeight: "500" }} className="mr-2">Select Course</label>
								<Form.Control
									as="select"
									value={selectedCourse}
									onChange={(e) => setSelectedCourse(e.target.value)}
									style={{ width: "fit-conetnt" }}
								>
									<option value="">Select Course</option>
									{/* <option value="1">
										Common University Entrance Test (CUET)
									</option>
									<option value="2">
										NATIONAL ELIGIBILITY CUM ENTRANCE TEST (NEET)
									</option>
									<option value="3">
										National Talent Search Examination (NTSE)
									</option>
									<option value="4">GENERAL APTITUDE TEST</option>
									<option value="5">NCERT (12th)</option>
									<option value="6">CLASS 10(SEBA)</option>
									<option value="7">Staff Selection Commission (SSC)</option>
									<option value="8">Online Classes</option>
									<option value="9">CUET (PG)</option>
									<option value="10">CLASS XII (Crash Course)</option> */}
									{
										courses?.map(({ courseId, courseName }) => (
											<option value={courseId}>{courseName}</option>
										)
										)
									}
								</Form.Control>
							</div>
							<div>
								{filteredCourses?.map((item, index) => (

									<div
										className='card mb-2'
										style={{ backgroundColor: index % 2 !== 0 ? "#fff" : "rgba(113, 38, 156, 0.29)" }}
									>
										<div className='card-body' style={{ width: "100%" }}>
											<h1 className='card-heading card-title p-0 m-0' style={{ fontSize: "20px", width: "100%" }}>
												{item.courseName}
											</h1>
											<h1 className='card-subheading  card-subtitle mb-2 text-muted'>
												{item.title}
											</h1>
											{/* <p className='card-description card-text'>
												Sed ut perspiciatis unde
												omnis iste natus error
												sit voluptatem
												accusantium{" "}
											</p> */}
											{/* <p>{item.quizCode}</p> */}
											<a
												onClick={() =>
													handleStartTest(
														item
													)
												}
												style={{
													cursor: "pointer",
												}}
												class='course-card-link card-link'
											>
												START TEST ➤
											</a>
										</div>
									</div>
									// </SwiperSlide>
								))}
								{/* </Swiper> */}
							</div>
						</div>
					</div>
				</div>
			</section >
		</>
	);
}

export default BestOnlinePlatform;
