import BlogImage from "../../Assets/images/blog-image.png";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Row, Col, Accordion } from "react-bootstrap";
import Blog from "../../Components/Copy/Blog/Blog";

function BlogPage() {
  const isSmallScreen = window.innerWidth <= 500;
  const faqQuestions = [
    {
      id: 1,
      faq: "What is BESST, and how does it support SEBA Class 10 students?",
      answer: `BESST is a platform dedicated to providing comprehensive support and study guidance for
      SEBA Class 10 students.It offers study materials, exam strategies, and tailored resources to
      enhance exam preparation.`,
    },
    {
      id: 2,
      faq: "Why are SEBA Class 10 exams important, and how can BESST help me succeed?",
      answer: `SEBA Class 10 exams are a significant milestone in your academic journey. BESST helps you
      succeed by providing a well-structured study plan, subject-specific resources, and valuable insights
      into exam strategies.`,
    },
    {
      id: 3,
      faq: "What resources does BESST offer for SEBA Class 10 subjects?",
      answer: `BESST provides a wide range of resources, including study materials for various subjects, exam
      tips, and solutions to previous years' question papers. These resources are designed to address the
      specific needs of SEBA Class 10 students.`,
    },
    {
      id: 4,
      faq: "How does BESST understand the nuances of the SEBA Class 10 curriculum and exam patterns?",
      answer:
        `BESST is designed to understand the intricacies of the SEBA Class 10 curriculum and exam
        patterns. The content is tailored to address these nuances, ensuring that students receive targeted
        and relevant study guidance.`,
    },
    {
      id: 5,
      faq: "What sets BESST apart in terms of exam strategies for SEBA Class 10?",
      answer:
        `BESST stands out by focusing on exam strategies. It offers insights into time management,
        question-solving techniques, and approaches to different subjects, empowering students to perform
        at their best in the SEBA Class 10 exams.`,
    },
    {
      id: 6,
      faq: "How can BESST help with time management during SEBA Class 10 exams?",
      answer: `BESST provides valuable insights and tips on effective time management strategies, helping
      students optimize their exam time and perform efficiently in the SEBA Class 10 exams.`,
    },
    {
      id: 7,
      faq: "Does BESST offer solutions to previous years' SEBA Class 10 question papers?",
      answer: `Yes, BESST provides solutions to previous years' SEBA Class 10 question papers. This helps
      students familiarize themselves with the exam pattern and practice solving questions effectively.`,
    },
    {
      id: 8,
      faq: "In what ways does BESST empower students to be confident during SEBA Class 10 exams?",
      answer: `BESST empowers students by offering comprehensive study materials, exam tips, and strategies.
      The goal is to build confidence and ensure that students are well-prepared to face the challenges of
      the SEBA Class 10 exams.`,
    },
    {
      id: 9,
      faq: "Can BESST help me with subject-specific challenges in SEBA Class 10?",
      answer: `Absolutely. BESST offers subject-specific resources to address the unique challenges of each
      SEBA Class 10 subject, ensuring that students have the support they need to excel in every area.`,
    },
    {
      id: 10,
      faq: "How can I access BESST and start my SEBA Class 10 exam preparation journey?",
      answer: `To access BESST and start your SEBA Class 10 exam preparation journey, simply visit our
      platform online. Explore the resources, study materials, and exam strategies available to kickstart
      your academic success.`,
    },
  ];
  return (
    <>
      <div className="blog-bg container-fluid mb-5">
        <div className="Contact_content">Blogs</div>
        <div className="Contact_slashContent">Home / Blogs</div>
      </div>
      {isSmallScreen ? (
        <Row>
          <Col md={12} sm={12} xs={12}>
            <div className="p-4">
              <div>
                <h1>Get Ready for Your Class 10 SEBA Exam with BESST</h1>
              </div>
              <div className="my-4">
                <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                <div className="author-name">By Chinmoy Mohan</div>
                <div className="text-secondary"><small>Published on November 14,2023</small></div>
                <div className="text-secondary"><small>2min read</small></div>
              </div>
              <img src={BlogImage} className="blog-img" alt="author" />
              <p style={{ textAlign: "justify" }}>
                Preparing for your Class 10 SEBA exams? You're in the right spot with BESST! We are dedicated to
                providing comprehensive support and study guidance tailored specifically to SEBA Class 10
                students. From study materials to exam strategies, BESST has got you covered.
              </p>
              <p style={{ textAlign: "justify" }}>
                The SEBA board, or Board of Secondary Education, Assam, is responsible for conducting the Class
                10 examinations in the state. These exams are a significant milestone in a student's academic
                journey. To succeed in the SEBA Class 10 exams, you need a well-structured and effective study
                plan.
              </p>
              <p style={{ textAlign: "justify" }}>
                BESST is designed to meet the specific needs of SEBA students. We provide a wide range of
                resources, including study materials for various subjects, exam tips, and solutions to previous years'
                question papers. We understand the nuances of the SEBA Class 10 curriculum and exam patterns,
                and our content is tailored to address these intricacies.
              </p>
              <p style={{ textAlign: "justify" }}>
                One of the key advantages of BESST is the focus on exam strategies. We offer insights into time
                management, question-solving techniques, and how to approach different subjects. All this
                information aims to empower you to perform your best in the SEBA Class 10 exams.
              </p>
              <p style={{ textAlign: "justify" }}>
                As you navigate through this crucial phase of your education, BESST will serve as your trusted
                companion, ensuring that you are well-prepared, confident, and ready to excel in the SEBA Class 10
                exams. Let's embark on this academic journey together.
              </p>
              <div className="blog-faq">
                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                  <div>
                    <h2 className="">FAQs</h2>
                  </div>
                </Col>

                <Col md={12} sm={12} xs={12} className="faq_margin">
                  {faqQuestions.map((item) => (
                    <Accordion
                      data-responsive-accordion-tabs="-medium-tabs large-tabs"
                      defaultActiveKey={item.id === 1 ? "0" : "1"}
                      key={item.id}
                      className="w-100"
                    >
                      <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                        <Accordion.Header className="accordion_header w-100">
                          {item.id}. {item.faq}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>{item.answer}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </Col>
              </div>
            </div>
          </Col>
          <Col md={12} sm={12} xs={12}>
            <div className="p-4">
              <h4>RELATED BLOGS</h4>
              <div className="blog-preview-container">
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 07,2024</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/prepare-cuet-pg-english-literature"} style={{ cursor: "pointer" }}>
                      How to prepare for CUET PG English Literature
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">October 28,2024</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-pg-online-coaching"} style={{ cursor: "pointer" }}>
                      CUET PG Online Coaching: Your Path to Success
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">August 07,2024</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-preparation"} style={{ cursor: "pointer" }}>
                      Mastering CUET: The Ultimate Guide to CUET Preparation and Success
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/blog"} style={{ cursor: "pointer" }}>
                      Class 10 SEBA Preparation Resources: Excel with BESST
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/evevate-your-cuet-preperation"} style={{ cursor: "pointer" }}>
                      Elevate Your CUET Preparation: BESST's Authentic CUET Mock Tests
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/anxiety-stress-management"} style={{ cursor: "pointer" }}>
                      CUET Exam Anxiety and Stress Management Techniques
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-study-tips"} style={{ cursor: "pointer" }}>
                      How to Prepare for CUET: Mastering the Exam with Proven Strategies
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <div className="blog-container">
            <div className="blog-content">
              <div>
                <h1>Get Ready for Your Class 10 SEBA Exam with BESST</h1>
              </div>
              <div className="author">
                <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                <div className="author-name">By Chinmoy Mohan</div> |
                <div>Published on November 14,2023</div> | <div>2min read</div>
              </div>
              <img src={BlogImage} className="blog-img" alt="author" />
              <p style={{ textAlign: "justify" }}>
                Preparing for your Class 10 SEBA exams? You're in the right spot with BESST! We are dedicated to
                providing comprehensive support and study guidance tailored specifically to SEBA Class 10
                students. From study materials to exam strategies, BESST has got you covered.
              </p>
              <p style={{ textAlign: "justify" }}>
                The SEBA board, or Board of Secondary Education, Assam, is responsible for conducting the Class
                10 examinations in the state. These exams are a significant milestone in a student's academic
                journey. To succeed in the SEBA Class 10 exams, you need a well-structured and effective study
                plan.
              </p>
              <p style={{ textAlign: "justify" }}>
                BESST is designed to meet the specific needs of SEBA students. We provide a wide range of
                resources, including study materials for various subjects, exam tips, and solutions to previous years'
                question papers. We understand the nuances of the SEBA Class 10 curriculum and exam patterns,
                and our content is tailored to address these intricacies.
              </p>
              <p style={{ textAlign: "justify" }}>
                One of the key advantages of BESST is the focus on exam strategies. We offer insights into time
                management, question-solving techniques, and how to approach different subjects. All this
                information aims to empower you to perform your best in the SEBA Class 10 exams.
              </p>
              <p style={{ textAlign: "justify" }}>
                As you navigate through this crucial phase of your education, BESST will serve as your trusted
                companion, ensuring that you are well-prepared, confident, and ready to excel in the SEBA Class 10
                exams. Let's embark on this academic journey together.
              </p>
              <div className="blog-faq">
                <Col md={12} sm={12} xs={12} className="faq_lay2 p-0">
                  <div>
                    <h2 className="">FAQs</h2>
                  </div>
                </Col>

                <Col md={12} sm={12} xs={12} className="faq_margin">
                  {faqQuestions.map((item) => (
                    <Accordion
                      data-responsive-accordion-tabs="-medium-tabs large-tabs"
                      defaultActiveKey={item.id === 1 ? "0" : "1"}
                      key={item.id}
                      className="w-100"
                    >
                      <Accordion.Item eventKey="0" style={{ margin: "1em" }}>
                        <Accordion.Header className="accordion_header w-100">
                          {item.id}. {item.faq}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>{item.answer}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </Col>
              </div>
            </div>

            <div className="right-pane">
              <h4>RELATED BLOGS</h4>
              <div className="blog-preview-container">
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 07,2024</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/prepare-cuet-pg-english-literature"} style={{ cursor: "pointer" }}>
                      How to prepare for CUET PG English Literature
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">October 28,2024</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-pg-online-coaching"} style={{ cursor: "pointer" }}>
                      CUET PG Online Coaching: Your Path to Success
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">August 07,2024</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-preparation"} style={{ cursor: "pointer" }}>
                      Mastering CUET: The Ultimate Guide to CUET Preparation and Success
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/blog"} style={{ cursor: "pointer" }}>
                      Class 10 SEBA Preparation Resources: Excel with BESST
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/evevate-your-cuet-preperation"} style={{ cursor: "pointer" }}>
                      Elevate Your CUET Preparation: BESST's Authentic CUET Mock Tests
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/anxiety-stress-management"} style={{ cursor: "pointer" }}>
                      CUET Exam Anxiety and Stress Management Techniques
                    </h5>
                  </div>
                </div>
                <div className="blog-preview">
                  <img
                    src={BlogPreviewImage}
                    className="blog-preview-image"
                    alt="thumbnail"
                  />
                  <div className="">
                    <h5 className="blog-date">November 14, 2023</h5>
                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/CUET-study-tips"} style={{ cursor: "pointer" }}>
                      How to Prepare for CUET: Mastering the Exam with Proven Strategies
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Blog />
        </div>
      )}

    </>
  );
}

export default BlogPage;
