import Author from "../../Assets/images/author.png";
import BlogImage from "../../Assets/images/blog-image.png";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Container, Row, Col, Image, Accordion, Button } from "react-bootstrap";
import { seoPageRoutes } from "../../RouteConstants";


function ServiceThree() {
    const isSmallScreen = window.innerWidth <= 500;
    const handleClick = () => {
        console.log(seoPageRoutes.bestOnlinePlatform);
        window.location.href = seoPageRoutes.bestOnlinePlatformCopy;
    }
    return (
        <>
            <div className="blog-bg container-fluid mb-5">
                <div className="Contact_content">Services</div>
                <div className="Contact_slashContent">Home / Services / Navigating Success: Counselling and Mock Registration for Central University
                    Admissions with BESST.in</div>
            </div>
            {isSmallScreen ? (
                <Row>
                    <Col md={8} sm={12} xs={12}>
                        <div className="p-4">
                            <div>
                                <h1>Navigating Success: Counselling and Mock Registration for Central University
                                    Admissions with BESST.in</h1>
                            </div>
                            <div className="my-4">
                                <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                                <div className="author-name">By Chinmoy Mohan</div>
                                <div className="text-secondary"><small>Published on November 14,2023</small></div>
                                <div className="text-secondary"><small>2min read</small></div>
                            </div>
                            <img src={BlogImage} alt="author" />
                            <p style={{ textAlign: "justify" }}>
                                In the dynamic landscape of higher education, gaining admission to prestigious central universities
                                is a goal that many students aspire to achieve. To ease this journey, BESST.in proudly offers a
                                unique blend of counseling and mock registration services, ensuring that aspirants are well-prepared
                                and confident as they navigate the competitive admission process.
                            </p>
                            <h1> Embarking on the Admission Journey</h1>
                            <p style={{ textAlign: "justify" }}>
                                The path to securing admission in central universities can be intricate, demanding careful navigation
                                and strategic preparation. Recognizing this, BESST.in introduces comprehensive counseling and
                                mock registration services, designed to empower students with the knowledge and confidence
                                needed to successfully tread the admission landscape.
                            </p>
                            <h1>BESST.in's Impactful Counselling Services</h1>
                            <p style={{ textAlign: "justify" }}>
                                BESST.in has played a pivotal role in shaping the academic trajectories of hundreds of students
                                through its insightful counseling services. Our experienced team of counselors understands the
                                nuances of various central university admission processes, offering personalized guidance to
                                students based on their aspirations, strengths, and academic backgrounds. Whether it's clarifying
                                doubts about courses, understanding eligibility criteria, or providing insights into the selection
                                process, our counseling services are tailored to empower students to make informed decisions.
                            </p>
                            <h1>Mock Registration: Bridging the Gap Between Aspiration and Reality</h1>
                            <p style={{ textAlign: "justify" }}>
                                The journey to central university admission often involves overcoming challenges unique to each
                                institution. BESST.in's dedicated team has facilitated this process by conducting mock registrations,
                                simulating the actual admission procedure. This hands-on experience not only familiarizes students
                                with the intricacies of the registration system but also helps them navigate potential obstacles
                                seamlessly. Through mock tests and realistic scenarios, we ensure that students are well-acquainted
                                with the entire admission process, leaving no room for surprises on the actual day.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                Empower your journey toward admission success by registering with BESST.in today. Avail the
                                benefits of our insightful counseling services and realistic mock registrations, providing you with
                                the knowledge and confidence needed to secure admission in central universities. Join the ranks of
                                students who have benefited from our comprehensive guidance.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                Register at BESST.in now to access our exclusive counseling and mock registration services.
                                Elevate your chances of admission success in central universities with BESST.in's unparalleled
                                support.
                            </p>
                        </div>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                        <div className="p-4">
                            <h4>RELATED SERVICES</h4>
                            <div className="blog-preview-container">
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 29,2024</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-mock-test"} style={{ cursor: "pointer" }}>
                                            CUET Mock Tests 2025: Your Path to Success
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/download-study-material"} style={{ cursor: "pointer" }}>
                                            Igniting Academic Triumph: The Potential of Downloadable Study Materials
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/counseling-mock-registration"} style={{ cursor: "pointer" }}>
                                            Navigating Success: Counselling and Mock Registration for Central University
                                            Admissions with BESST.in
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/previous-year-question-bank"} style={{ cursor: "pointer" }}>
                                            Exploring Triumph: Harnessing the Strength of BESST's Previous Year
                                            Question Bank
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) :
                (<div className="blog-container">
                    <div className="blog-content">
                        <div>
                            <h1>Navigating Success: Counselling and Mock Registration for Central University
                                Admissions with BESST.in</h1>
                        </div>
                        <div className="author">
                            <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                            <div className="author-name">By Chinmoy Mohan</div> |
                            <div>Published on November 14,2023</div> | <div>2min read</div>
                        </div>
                        <img src={BlogImage} alt="author" />
                        <p style={{ textAlign: "justify" }}>
                            In the dynamic landscape of higher education, gaining admission to prestigious central universities
                            is a goal that many students aspire to achieve. To ease this journey, BESST.in proudly offers a
                            unique blend of counseling and mock registration services, ensuring that aspirants are well-prepared
                            and confident as they navigate the competitive admission process.
                        </p>
                        <h1> Embarking on the Admission Journey</h1>
                        <p style={{ textAlign: "justify" }}>
                            The path to securing admission in central universities can be intricate, demanding careful navigation
                            and strategic preparation. Recognizing this, BESST.in introduces comprehensive counseling and
                            mock registration services, designed to empower students with the knowledge and confidence
                            needed to successfully tread the admission landscape.
                        </p>
                        <h1>BESST.in's Impactful Counselling Services</h1>
                        <p style={{ textAlign: "justify" }}>
                            BESST.in has played a pivotal role in shaping the academic trajectories of hundreds of students
                            through its insightful counseling services. Our experienced team of counselors understands the
                            nuances of various central university admission processes, offering personalized guidance to
                            students based on their aspirations, strengths, and academic backgrounds. Whether it's clarifying
                            doubts about courses, understanding eligibility criteria, or providing insights into the selection
                            process, our counseling services are tailored to empower students to make informed decisions.
                        </p>
                        <h1>Mock Registration: Bridging the Gap Between Aspiration and Reality</h1>
                        <p style={{ textAlign: "justify" }}>
                            The journey to central university admission often involves overcoming challenges unique to each
                            institution. BESST.in's dedicated team has facilitated this process by conducting mock registrations,
                            simulating the actual admission procedure. This hands-on experience not only familiarizes students
                            with the intricacies of the registration system but also helps them navigate potential obstacles
                            seamlessly. Through mock tests and realistic scenarios, we ensure that students are well-acquainted
                            with the entire admission process, leaving no room for surprises on the actual day.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Empower your journey toward admission success by registering with BESST.in today. Avail the
                            benefits of our insightful counseling services and realistic mock registrations, providing you with
                            the knowledge and confidence needed to secure admission in central universities. Join the ranks of
                            students who have benefited from our comprehensive guidance.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Register at BESST.in now to access our exclusive counseling and mock registration services.
                            Elevate your chances of admission success in central universities with BESST.in's unparalleled
                            support.
                        </p>
                    </div>

                    <div className="right-pane">
                        <h4>RELATED SERVICES</h4>
                        <div className="blog-preview-container">
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 29,2024</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-mock-test"} style={{ cursor: "pointer" }}>
                                        CUET Mock Tests 2025: Your Path to Success
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/download-study-material"} style={{ cursor: "pointer" }}>
                                        Igniting Academic Triumph: The Potential of Downloadable Study Materials
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/counseling-mock-registration"} style={{ cursor: "pointer" }}>
                                        Navigating Success: Counselling and Mock Registration for Central University
                                        Admissions with BESST.in
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/previous-year-question-bank"} style={{ cursor: "pointer" }}>
                                        Exploring Triumph: Harnessing the Strength of BESST's Previous Year
                                        Question Bank
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
            <div className="blog-bg container-fluid d-flex">
                <Container>
                    <Row>
                        <Col sm={8} md={9} xs={12}>
                            <p className="abso_p">
                                Don't miss this opportunity to enhance your skills and
                                confidence, take free mock tests now!
                            </p>
                        </Col>
                        <Col sm={4} md={3} xs={12} className=" d-flex align-items-center w-100 justify-content-center">
                            <button type="button" className="sub_button1 btn" onClick={handleClick}>
                                Take Free Test
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ServiceThree;
